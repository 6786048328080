import React, { useEffect, useState } from 'react';

const OtherBacktested = ({page}) => {
  const [data,setData]=useState([]);
  const [nifty,setNifty]=useState("");
  

  useEffect(()=>{
    const fun=()=>{
      if(page=="ALPHA-S&P"){
        setData(data1)
        setNifty("S&P 500")
      }
      else if(page=="ALPHA-NDX"){
        setData(data2)
        setNifty("NDX 100")
      }
    }
    fun();
    console.log("data",data)
      
  },[page])

  const data1 = [
    { year: '2011', niftyOther: '0.00%', alpha: '7.41%', alphaBg: '#B9E7C5' },
    { year: '2012', niftyOther: '13.40%', alpha: '35.86%', alphaBg: '#B9E7C5' },
    { year: '2013', niftyOther: '29.60%', alpha: '35.81%', alphaBg: '#B9E7C5' },
    { year: '2014', niftyOther: '11.39%', alpha: '13.32%', alphaBg: '#B9E7C5' },
    { year: '2015', niftyOther: '-0.73%', alpha: '15.51%', alphaBg: '#B9E7C5' },
    { year: '2016', niftyOther: '9.54%', alpha: '38.69%', alphaBg: '#B9E7C5' },
    { year: '2017', niftyOther: '19.42%', alpha: '23.57%', alphaBg: '#B9E7C5' },
    { year: '2018', niftyOther: '-6.24%', alpha: '-6.82%', alphaBg: '#FAC7C1' },
    { year: '2019', niftyOther: '28.88%', alpha: '41.43%', alphaBg: '#B9E7C5' },
    { year: '2020', niftyOther: '16.26%', alpha: '98.20%', alphaBg: '#B9E7C5' },
    { year: '2021', niftyOther: '26.89%', alpha: '1.26%', alphaBg: '#FAC7C1' },
    { year: '2022', niftyOther: '-19.44%', alpha: '-1.24%', alphaBg: '#B9E7C5' },
    { year: '2023', niftyOther: '15.99%', alpha: '30.32%', alphaBg: '#B9E7C5' },
  ];

  const data2 = [
    { year: '2011', niftyOther: '2.7%', alpha: '3.61%', alphaBg: '#B9E7C5' },
    { year: '2012', niftyOther: '16.8%', alpha: '15.33%', alphaBg: '#FAC7C1'  },
    { year: '2013', niftyOther: '35.0%', alpha: '49.47%', alphaBg: '#B9E7C5' },
    { year: '2014', niftyOther: '17.9%', alpha: '16.96%', alphaBg: '#FAC7C1'  },
    { year: '2015', niftyOther: '8.4%', alpha: '13.83%', alphaBg: '#B9E7C5' },
    { year: '2016', niftyOther: '5.9%', alpha: '27.27%', alphaBg: '#B9E7C5' },
    { year: '2017', niftyOther: '31.5%', alpha: '10.70%', alphaBg: '#FAC7C1'  },
    { year: '2018', niftyOther: '-1.0%', alpha: '10.19%', alphaBg: '#B9E7C5' },
    { year: '2019', niftyOther: '38.0%', alpha: '55.00%', alphaBg: '#B9E7C5' },
    { year: '2020', niftyOther: '47.7%', alpha: '115.72%', alphaBg: '#B9E7C5' },
    { year: '2021', niftyOther: '26.6%', alpha: '19.93%', alphaBg: '#FAC7C1' },
    { year: '2022', niftyOther: '-33.0%', alpha: '-25.02%', alphaBg: '#B9E7C5' },
    { year: '2023', niftyOther: '29.0%', alpha: '6.38%', alphaBg: '#FAC7C1' },
  ];
  
  
  

  return (
    <div className="pt-[38px]">
      {/* Desktop View */}
      <div className="w-full overflow-x-scroll md:overflow-hidden">
        <table className="table-auto border-[1px] text-[14px] font-poppins border-[#cbcbcb] w-full">
          <thead>
            <tr className="bg-white text-black">
              <th className="p-[5px] border-[1px] text-left text-[14px] font-poppins border-[#cbcbcb]">Year</th>
              {data.slice(0, 13).map((row, index) => (
                <th key={index} className="p-[5px] text-right text-[14px] font-poppins border-[1px] border-[#cbcbcb]">
                  {row.year}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-[#ffffff] text-black">
              <td className="px-[5px] py-[10px] border-[1px] text-[14px] font-poppins font-semibold text-left border-[#cbcbcb]">{nifty}</td>
              {data.slice(0, 13).map((row, index) => (
                <td key={index} className="px-[5px] text-right text-[14px] font-poppins border-[1px] border-[#cbcbcb]">
                  {row.niftyOther}
                </td>
              ))}
            </tr>
      
            <tr className="bg-[#ffffff] text-black">
              <td className={`px-[5px] ${page === "ALPHA-NDX" ? "py-[10px]" : "py-[0px]"} font-poppins text-[14px] text-left border-[1px] font-semibold border-[#cbcbcb]`}
              >{page}</td>
              {data.slice(0, 13).map((row, index) => (
                <td
                  key={index}
                  className="px-[3px] py-[0px] text-right text-[14px] font-poppins border-[1px] border-[#cbcbcb]"
                  style={{ backgroundColor: row.alphaBg }}
                >
                  {row.alpha}
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        {/* <table className="table-auto border-[1px] border-gray-300 w-full mt-8">
          <thead>
            
            <tr className="bg-[#ffffff] text-black">
              <th className="p-4 border-[1px] border-[#959595]">YEAR</th>
              {data.slice(7).map((row, index) => (
                <th key={index} className="p-4 border-[1px] border-[#959595]">
                  {row.year}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-[#ffffff] text-black">
              <td className="p-4 border-[1px] font-semibold text-center border-[#959595]">{nifty}</td>
              {data.slice(7).map((row, index) => (
                <td key={index} className="p-4 border-[1px] border-[#959595]">
                  {row.niftyOther}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              <td className="p-4 border-[1px] font-semibold text-center border-[#959595]">{page}</td>
              {data.slice(7).map((row, index) => (
                <td
                  key={index}
                  className="p-4 border-[1px] border-[#959595]"
                  style={{ backgroundColor: row.alphaBg }}
                >
                  {row.alpha}
                </td>
              ))}
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
};

export default OtherBacktested;
