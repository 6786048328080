import React from "react";
import ContainerPoints from "../ContainerPoints";
import styles from "../PrivacyPolicy.module.css";

function ContainerTwelve(props) {
  return (
    <div
      className={props.no === "1" ? styles.sectionmain : styles.sectionmain1}
    >
      <div className={styles.Heading}>
        {props.no}.{props.heading}
      </div>

      <p>{props.para1 && props.para1}</p>
      {props.roman1 && (
        <>
          <div className={styles.HeadingRoman}>
            {" "}
            {props.roman1 && props.roman1} {props.heading1 && props.heading1}
          </div>

          <ContainerPoints
            bullet1={props.Section3bullet1}
            bullet2={props.Section3bullet2}
            bullet3={props.Section3bullet3}
            bullet4={props.Section3bullet4}
            bullet5={props.Section3bullet5}
            bullet6={props.Section3bullet6}
            paraFooter1={props.paraSection1Footer1}
          />
        </>
      )}
      {props.roman2 && (
        <>
          <div style={{ margin: "25px auto " }} className={styles.HeadingRoman}>
            {" "}
            {props.roman2 && props.roman2} {props.heading2 && props.heading2}
          </div>
        </>
      )}

      {props.roman3 && (
        <>
          <div style={{ margin: "25px auto " }} className={styles.HeadingRoman}>
            {" "}
            {props.roman3 && props.roman3} {props.heading3 && props.heading3}
          </div>
        </>
      )}

      <p>{props.para2 && props.para2}</p>
      <p>{props.para3 && props.para3}</p>
      <p>{props.para4 && props.para4}</p>
    </div>
  );
}

export default ContainerTwelve;
