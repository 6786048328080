import React from "react";

const UpdateBox = () => {
  return (
    <div className="py-20 px-4 md:px-36 bg-[#87CEEB]/30">

    
      <div className="hiddenAnimation text-[#211f54] text-2xl md:text-[35px] md:leading-[40px] text-center font-bold font-sans">
        {" "}
        Want to get our best research delivered <br />
        straight to your inbox?
      </div>
      <div className="hiddenAnimation text-center text-base text-lg text-[#000000] mt-4">
        Join thousands of sophisticated investors and get our best insights on
        portfolio <br />
        construction and diversification delivered directly to your inbox.
      </div>
      <div className="hiddenAnimation flex flex-col md:flex-row justify-center mt-12">
        <input
          className="w-full py-4 md:w-[40%] px-8 bg-white rounded-[4px]"
          type="email"
          placeholder="Enter your email"
        />
        <button className="mt-6 md:mt-0 md:ml-8 md:px-12 py-4 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[4px] hover:scale-105 cursor-pointer">
          {" "}
          Get Updates
        </button>
      </div>
    </div>
  );
};

export default UpdateBox;
