import React from "react";
import styles from "./Terms.module.css";
import Container from "../PrivacyPolicy/Container";
import { TermsContain } from "./Data";
import TermsSeven from "./TermsSeven";
import StrategyNavbar from "../Navbar/StrategyNavbar";
import Footer from "../Footer/Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <StrategyNavbar />
      <div className={styles.containermain}>
        <div className={styles.Heading}>Terms & Conditions</div>
        {TermsContain.map((ele, index) => (
          <div key={index}>
            <Container
              heading={ele.sectionOne.heading}
              no={ele.sectionOne.no}
              terms={ele.sectionOne.terms}
              para3={ele.sectionOne.para3}
              para4={ele.sectionOne.para4}
              para5={ele.sectionOne.para5}
            />
            <Container
              heading={ele.sectionTwo.heading}
              no={ele.sectionTwo.no}
              terms2={ele.sectionTwo.terms2}
            />
            <Container
              heading={ele.sectionThree.heading}
              no={ele.sectionThree.no}
              para={ele.sectionThree.para}
              para1={ele.sectionThree.para1}
              para2={ele.sectionThree.para2}
              para3={ele.sectionThree.para3}
              para4={ele.sectionThree.para4}
              para5={ele.sectionThree.para5}
            />
            <Container
              heading={ele.sectionFour.heading}
              no={ele.sectionFour.no}
              para1={ele.sectionFour.para1}
            />
            <Container
              heading={ele.sectionFive.heading}
              no={ele.sectionFive.no}
              terms5={ele.sectionFive.term5}
            />
            <Container
              heading={ele.sectionSix.heading}
              no={ele.sectionSix.no}
              para1={ele.sectionSix.para1}
            />
            <TermsSeven
              heading={ele.sectionSeven.heading}
              no={ele.sectionSeven.no}
              paraHeading1={ele.sectionSeven.paraHeading1}
              paraHeading2={ele.sectionSeven.paraHeading2}
              bullet1={ele.sectionSeven.bullet1}
              bullet2={ele.sectionSeven.bullet2}
              bullet3={ele.sectionSeven.bullet3}
              bullet4={ele.sectionSeven.bullet4}
              bullet5={ele.sectionSeven.bullet5}
              bullet6={ele.sectionSeven.bullet6}
              bullet7={ele.sectionSeven.bullet7}
              bullet8={ele.sectionSeven.bullet8}
              bullet9={ele.sectionSeven.bullet9}
              bullet10={ele.sectionSeven.bullet10}
              bullet11={ele.sectionSeven.bullet11}
              bullet12={ele.sectionSeven.bullet12}
              bullet13={ele.sectionSeven.bullet13}
              bullet14={ele.sectionSeven.bullet14}
              bullet15={ele.sectionSeven.bullet15}
            />
            <Container
              heading={ele.sectionEight.heading}
              no={ele.sectionEight.no}
              para1={ele.sectionEight.para1}
            />
            <Container
              heading={ele.sectionNine.heading}
              no={ele.sectionNine.no}
              para1={ele.sectionNine.para1}
            />
            <Container
              heading={ele.sectionTen.heading}
              no={ele.sectionTen.no}
              para={ele.sectionTen.para}
              para1={ele.sectionTen.para1}
              para2={ele.sectionTen.para2}
              para3={ele.sectionTen.para3}
              para4={ele.sectionTen.para4}
            />
            <Container
              heading={ele.sectionEleven.heading}
              no={ele.sectionEleven.no}
              para1={ele.sectionEleven.para1}
            />
            <Container
              heading={ele.sectionTwelve.heading}
              no={ele.sectionTwelve.no}
              para1={ele.sectionTwelve.para1}
              para2={ele.sectionTwelve.para2}
              para3={ele.sectionTwelve.para3}
            />
            <TermsSeven
              heading={ele.sectionThirteen.heading}
              no={ele.sectionThirteen.no}
              paraHeading1={ele.sectionThirteen.paraHeading1}
              bullet1={ele.sectionThirteen.bullet1}
              bullet2={ele.sectionThirteen.bullet2}
              bullet3={ele.sectionThirteen.bullet3}
              bullet4={ele.sectionThirteen.bullet4}
              bullet5={ele.sectionThirteen.bullet5}
              bullet6={ele.sectionThirteen.bullet6}
              footer={ele.sectionThirteen.footer}
            />

            <Container
              heading={ele.sectionFourteen.heading}
              no={ele.sectionFourteen.no}
              para1={ele.sectionFourteen.para1}
            />
            <Container
              heading={ele.sectionFifteen.heading}
              no={ele.sectionFifteen.no}
              para={ele.sectionFifteen.para}
              para1={ele.sectionFifteen.para1}
              para2={ele.sectionFifteen.para2}
              para3={ele.sectionFifteen.para3}
              para4={ele.sectionFifteen.para4}
              para5={ele.sectionFifteen.para5}
            />
            <Container
              heading={ele.sectionSixteen.heading}
              no={ele.sectionSixteen.no}
              para={ele.sectionSixteen.para}
              para1={ele.sectionSixteen.para1}
              para2={ele.sectionSixteen.para2}
              para3={ele.sectionSixteen.para3}
              para4={ele.sectionSixteen.para4}
            />
            <Container
              heading={ele.sectionSeventeen.heading}
              no={ele.sectionSeventeen.no}
              para1={ele.sectionSeventeen.para1}
            />
            <Container
              heading={ele.sectionEightteen.heading}
              no={ele.sectionEightteen.no}
              para={ele.sectionEightteen.para}
              para1={ele.sectionEightteen.para1}
              para2={ele.sectionEightteen.para2}
              para3={ele.sectionEightteen.para3}
            />
            <Container
              heading={ele.sectionNineteen.heading}
              no={ele.sectionNineteen.no}
              para={ele.sectionNineteen.para}
              para1={ele.sectionNineteen.para1}
              para2={ele.sectionNineteen.para2}
            />
            <Container
              heading={ele.sectionTwenty.heading}
              no={ele.sectionTwenty.no}
              para1={ele.sectionTwenty.para1}
            />
            <Container
              heading={ele.sectionTwentyOne.heading}
              no={ele.sectionTwentyOne.no}
              para={ele.sectionTwentyOne.para}
              para1={ele.sectionTwentyOne.para1}
              para2={ele.sectionTwentyOne.para2}
              para3={ele.sectionTwentyOne.para3}
            />
            <Container
              heading={ele.sectionTwentyTwo.heading}
              no={ele.sectionTwentyTwo.no}
              para={ele.sectionTwentyTwo.para}
              para1={ele.sectionTwentyTwo.para1}
              para2={ele.sectionTwentyTwo.para2}
            />
            <Container
              heading={ele.sectionTwentyThree.heading}
              no={ele.sectionTwentyThree.no}
              para={ele.sectionTwentyThree.para}
              para1={ele.sectionTwentyThree.para1}
            />
            <Container
              heading={ele.sectionTwentyFour.heading}
              no={ele.sectionTwentyFour.no}
              paraContact="true"
            />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
