import React, { useState, useEffect } from "react";

import { ChevronLeft } from "lucide-react";
import axios from "axios";

const ContactUsForm = ({ setShow }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateEmail = () => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!pattern.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = () => {
    if (number.length !== 10) {
      setPhoneError("Phone number should be 10 digits");
    } else {
      setPhoneError("");
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    validateEmail();
    validatePhone();

    if (!name || !email || !number) {
      setIsLoading(false);
      setErrorShow(true);
      setError("All Fields are required");
    } else if (emailError || phoneError) {
      setIsLoading(false);
      setErrorShow(true);
      setError("Please fix the validation errors");
    } else {
      let data = JSON.stringify({
        name: name,
        email: email,
        number: number,
        message: message,
      });

      let config = {
        method: "post",
        url: "https://server.sypto.xyz/sypto/api/future-bits/second-street",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log("res", response);
          setIsLoading(false);
          setIsSubmit(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        setEmailError("");
        setPhoneError("");
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);
  useEffect(() => {
    if (isSubmit) {
      setIsSubmit(true);
      const toRef = setTimeout(() => {
        setIsSubmit(false);
        setEmail("");
        setMessage("");
        setName("");
        setNumber("");
        setShow(false);
        clearTimeout(toRef);
      }, 5000);
    }
  }, [isSubmit]);

  return (
    <div className="flex  fixed inset-0  items-center justify-center bg-black bg-opacity-75 z-50">
      <div className=" relative bg-[#243444] w-full md:w-[35%] rounded-[20px] px-5 py-4 md:px-10 md:py-6 m-2">
        <div
          className="py-3 flex flex-row items-center  text-[#ffffff]/50 font-bold hover:text-white cursor-pointer "
          onClick={() => {
            setShow(false);
          }}
        >
          <ChevronLeft className="w-5 h-5 mr-2" />
          Back
        </div>

        {errorShow === true ? (
          <div className="py-3 text-[#ff0000]">{error} </div>
        ) : null}
        <div className="text-white text-sm pb-3">Provide Your Details</div>
        <div className="text-white">Name</div>
        <input
          type="text"
          placeholder="Enter Your name"
          onChange={(e) => setName(e.target.value)}
          className="mt-4 py-3 px-3 w-full rounded-lg"
        />
        <div className="text-white mt-3">Email</div>
        {emailError && <span style={{ color: "red" }}>{emailError}</span>}
        <input
          type="email"
          placeholder="Enter Your email"
          onChange={(e) => setEmail(e.target.value)}
          className="mt-4 py-3 px-3 w-full rounded-lg"
        />
        <div className="mt-3 text-white">Phone Number</div>
        {phoneError && <span style={{ color: "red" }}>{phoneError}</span>}
        <input
          type="number"
          placeholder="Enter your number"
          onChange={(e) => setNumber(e.target.value)}
          className="mt-4 py-3 px-3 w-full rounded-lg"
        />

        <div className="mt-3 text-white">Message</div>
        <textarea
          type="text"
          placeholder="Enter your message here"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className={` mt-4 py-3 px-3 w-full rounded-lg focus:outline-none `}
          style={{ height: "90px" }}
        />

        {isSubmit === true ? (
          <div className="py-3 text-[#00FF00]">
            We will contact you shortly{" "}
          </div>
        ) : null}
        <div
          className="mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
          onClick={handleSubmit}
        >
          {isLoading === true ? (
            <span className="flex flex-row justify-center">
              <svg
                className="h-10 w-10 text-[#142E44] animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          ) : (
            "Submit"
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
