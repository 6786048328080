import React from "react";
import Lock from "../assests/EnsureFlexibility/lock.png";
import Investment from "../assests/EnsureFlexibility/investment.png";
import ReturnInvestment from "../assests/EnsureFlexibility/return-of-investment.png";
import ConnectNow from "./ConnectNowButton";

const EnsurityFlexibility = () => {
  const ele = [
    {
      img: ReturnInvestment,
      title: "Ease of Transactions:",
      desc: "Quick conversions of holdings into cash, allowing hassle-free buy and sell processes for investors.",
    },
    {
      img: Lock,
      title: "No Lock-in Period:",
      desc: "Investors are free to redeem their investment whenever they wish, ensuring immediate access* to their capital.",
    },
    {
      img: Investment, // Fixed the repeated image, now using Investment instead of ReturnInvestment.
      title: "Empowered Investing:",
      desc: "Offering investors unparalleled flexibility and control over their financial strategies.",
    },
  ];

  return (
    <div className="hiddenAnimation w-full justify-center items-center">
      <div className="hiddenAnimation pb-[30px] pt-20 md:pb-[66px] text-[#211f54] text-2xl md:text-[38px] md:leading-[62px] text-center font-bold font-sans">
        Ensuring Flexibility : Liquidity & Redemption
      </div>
      <div className="md:flex justify-center items-center pt-4">
        <div className="md:w-[25%] md:h-[40vh] h-[38vh] justify-center items-center  ">
          <div className="mx-6 h[25vh] flex flex-col justify-center items-center mt-6 px-4 pb-[45px] bg-white ">
            <img
              className="w-[120px] pb-6 h-[120px] justify-center items-center"
              src={ele[0].img}
              alt={ele[0].title}
            />
            <div className="pl-2 pt-6">
              <div className="text-xl pb-4 md:text-xl font-bold text-[#000000] text-center font-sans">
                {ele[0].title}
              </div>
              <div className="text-center text-base text-[#000000]/80 font-rubik font-normal">
                {ele[0].desc}
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[25%] md:h-[40vh] h-[38vh] justify-center items-center border-spacing-y-[12px] border-dashed md:border-l-2 md:border-r-2 ">
          <div className="mx-6 h[25vh] flex flex-col justify-center items-center px-4 md:mt-6 pb-[45px] bg-white ">
            <img
              className="w-[120px] pb-6 h-[120px] justify-center items-center"
              src={ele[1].img}
              alt={ele[1].title}
            />
            <div className="pl-2 pt-6">
              <div className="text-xl md:text-xl font-bold text-[#000000] text-center pb-4 font-sans">
                {ele[1].title}
              </div>
              <div className="text-center text-base text-[#000000]/80 pb-6 font-rubik font-normal">
                {ele[1].desc}
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[25%] md:h-[40vh] h-[38vh] justify-center items-center  ">
          <div className="mx-6 h[25vh] flex flex-col justify-center items-center px-4 mt-6 pb-[45px] bg-white ">
            <img
              className="w-[120px] pb-6 h-[120px] justify-center items-center"
              src={ele[2].img}
              alt={ele[2].title}
            />
            <div className="pl-2 pt-6">
              <div className="text-xl md:text-xl font-bold text-[#000000] text-center pb-4 font-sans">
                {ele[2].title}
              </div>
              <div className="text-center text-base text-[#000000]/80 font-rubik font-normal">
                {ele[2].desc}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hiddenAnimation flex flex-row pb-[100px] md:pt-[50px] justify-center md:mt-16">
        <ConnectNow />
      </div>
    </div>
  );
};

export default EnsurityFlexibility;
