import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import UpdateBox from "../Footer/UpadteBox";
import Footer from "../Footer/Footer";
import animation from "../common/animation";

import First from "../assests/aboutUs/AboutUs.svg";
import Review from "./Review";
import HallMarks from "./HallMarks";
import ExpertPillers from "./ExpertisePillers";

const style = {
  paragraph:
    "hiddenAnimation text-base text-[#000000]/80 leading-[28px] font-rubik font-normal",
};
const AboutUs = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div>
      <Navbar />
      <div className="pt-24 pb-12 px-4 md:px-36">
        <div className="flex flex-col md:flex-row md:mt-8">
          <div className="w-full md:w-[60%]">
            <div className="hiddenAnimation text-black text-[32px] laading-[62px] font-rubik font-bold">
              About Us
            </div>
            <div className={`mt-4 md:mt-8 w-full md:w-[90%] ${style.paragraph}`}>
              Founded in 2002, we are a leading global alternative investment
              management platform, specialising in global macro and digital
              assets. We manage assets for institutional investors around the
              world including sovereign wealth funds, corporate and public
              pension plans, foundations and endowments.
            </div>
            <div className={`mt-4 md:mt-8 w-full md:w-[90%] ${style.paragraph}`}>
              We have over 1100+ team members, 170+ Portfolio Managers / Traders
              / Pod PMs and 08 Hubs with offices in London, New York, Geneva,
              Jersey, Hong Kong, Abu Dhabi, Singapore, Austin, Chicago and the
              Cayman Islands. The firm is led by CEO, Aron Landy. By utilising
              our exceptional talent base, innovative technology and tenured
              institutional infrastructure, we seek to deliver the best results
              possible for our clients whilst staying at the forefront of our
              evolving industry.
            </div>
          </div>
          <div className="hiddenAnimation mt-8 md:mt-0">
            <img src={First} alt="About Us" className="w-[93%]" />
          </div>
        </div>
        <div className={`mt-8 ${style.paragraph}`}>
          Founded in 2002, we are a leading global alternative investment
          management platform, specialising in global macro and digital assets.
          We manage assets for institutional investors around the world
          including sovereign wealth funds, corporate and public pension plans,
          foundations and endowments. We have over 1100+ team members, 170+
          Portfolio Managers / Traders / Pod PMs and 08 Hubs with offices in
          London, New York, Geneva, Jersey, Hong Kong, Abu Dhabi, Singapore,
          Austin, Chicago and the Cayman Islands. The firm is led by CEO, Aron
          Landy. By utilising our exceptional talent base, innovative technology
          and tenured institutional infrastructure, we seek to deliver the best
          results possible for our clients whilst staying at the forefront of
          our evolving industry.
        </div>
        <div className={`mt-4 md:mt-6 ${style.paragraph}`}>
          We have over 1100+ team members, 170+ Portfolio Managers / Traders /
          Pod PMs and 08 Hubs with offices in London, New York, Geneva, Jersey,
          Hong Kong, Abu Dhabi, Singapore, Austin, Chicago and the Cayman
          Islands. The firm is led by CEO, Aron Landy. By utilising our
          exceptional talent base, innovative technology and tenured
          institutional infrastructure, we seek to deliver the best results
          possible for our clients whilst staying at the forefront of our
          evolving industry.
        </div>
      </div>
      <Review />
      <HallMarks />
      <ExpertPillers />
      <UpdateBox />
      <Footer />
    </div>
  );
};

export default AboutUs;
