import React, { useEffect, useRef, useState } from "react";
import animation from "../common/animation";
import StrategyNavbar from "../Navbar/StrategyNavbar";

import GetStarted from "./GetStarted";
// import Faq from "./Faq";
// import UpdateBox from "../Footer/UpadteBox";
import Footer from "../Footer/Footer";

import Pdf from "../assests/pdf.svg";

import FixedComponent from "./FixedComponent";
import FooterDisclaimer from "./FooterDiscliamer";
import FirstDisclaimer from "./FirstDisclaimer";
import LivePerformace from "./LivePerformance";
import StrategyDescription from "./StrategyDescription";
import BacktestedPerformance from "./BacktestedPerformace";
import WhyAlpha from "./WhyAlpha";
import OtherDiscription from "./OtherDescription";
import Backtested from "../common/Backtested";

import axios from "axios";
import ContactUsForm from "../Footer/ContactUsForm";
import RiskManagement from "../common/RiskManagement";
import Statistics from "../common/Statistics";
const data = [
  {
    heading: "Management Fee",
    desc: "1%",
  },
  {
    heading: "Performance Fee",
    desc: "20%",
  },
  {
    heading: "Min. Investment",
    desc: "$100,000",
  },
  {
    heading: "Redemptions",
    desc: "Monthly with 10 days notice",
  },
  {
    heading: "IRA Eligible",
    desc: "Yes",
  },
  {
    heading: "Investor Type",
    desc: "Accredited",
  },
  {
    heading: "Investor Eligibility",
    desc: "Global",
  },
];

const newData = [
  {
    imageUrl: Pdf,
    name: "Tear Sheet Investment",
  },
  {
    imageUrl: Pdf,
    name: "Indian Investments Presentation",
  },
  {
    imageUrl: Pdf,
    name: "Options Investments Presentation",
  },
];
const backtested = [
  {
    imageUrl: Pdf,
    name: "Tear Sheet",
  },
  {
    imageUrl: Pdf,
    name: "Presentation",
  },
  {
    imageUrl: Pdf,
    name: "Investment Memo",
  },
];

const Alpha200 = () => {
  const sidebarRef = useRef(null);
  const contentWrapperRef = useRef(null);

  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const contentWrapper = contentWrapperRef.current;

      if (sidebar && contentWrapper) {
        const scrollTop = window.scrollY;

        if (scrollTop >= 2500) {
          setIsAtEnd(true);
        } else {
          setIsAtEnd(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);

  const [liveData, setLiveData] = useState();
  const getData = () => {
    axios
      .get("https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/alpha-200")
      .then((res) => {
        setLiveData(res.data.values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  //chart data

  const alphaTwoHPerformceAccountValue =
    liveData && liveData.map((item) => [item[0], item[1], item[2]]);

  const chartData =
    alphaTwoHPerformceAccountValue &&
    alphaTwoHPerformceAccountValue.map((innerArray, index) =>
      innerArray.map((value, columnIndex) => {
        // Skip the first column
        if (columnIndex === 0 || typeof value !== "string") {
          return value;
        }

        // Remove the leading rupee sign and commas, then convert to a number
        return parseFloat(value.replace(/^₹|,/g, ""));
      })
    );
  // table data
  const new200Data = liveData
    ? liveData.map((item) => [item[0], item[5], item[6]])
    : [];
  const data200Array = new200Data ? new200Data : [];

  const alpha200MonthlyReturns = [data200Array[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const unique200MonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < data200Array.length; i++) {
    const currentDate = data200Array[i][0];

    // Check if currentDate is defined and is a string
    if (currentDate && typeof currentDate === "string") {
      const currentMonthKey = currentDate.split("/")[1]; // Extract the month part

      // Check if the current month is not already in the uniqueMonthsSet and the data is not empty or undefined
      if (!unique200MonthsSet.has(currentMonthKey)) {
        // Check if all values in the current row are not undefined
        const allDefined = data200Array[i].every(
          (value) => value !== undefined
        );

        // Add the row to the new array only if all values are not undefined
        if (allDefined) {
          alpha200MonthlyReturns.push(data200Array[i]);
          unique200MonthsSet.add(currentMonthKey);
        }
      }
    }
  }

  const [show, setShow] = useState(false);

  return (
    <div>
      <StrategyNavbar />
      <div className=" relative  flex flex-col md:flex-row justify-between pt-24 md:pt-32 pb-8 md:pb-32 px-4 md:px-36">
        <div className="w-full md:w-[75%] md:min-h-full ">
          <div className=" text-xl md:text-[32px] md:leading-[62px] font-rubik font-bold">
            Alpha 200 Strategy
          </div>
          <div className="text-sm font-rubik text-[#000000]/70">Momentum Strategy to beat Nifty 200 with its own constituents.</div>
          <div className="pt-12 font-rubik text-[18px] opacity-70"> Alpha 200 employs quantitative algorithms to pick 10 stocks from the NIFTY 200. Recognizing the higher beta of mid-cap stocks, this strategy pursues greater returns over a three-year period but acknowledges the potential for higher drawdowns than a pure Large Cap strategy. The strategy balances the pursuit of returns with managing drawdown risks.</div>
          
          <StrategyDescription />
          <FirstDisclaimer />
          <LivePerformace
            monthlyReturns={alpha200MonthlyReturns && alpha200MonthlyReturns}
            titleOne="Alpha 200"
            titleTwo="Nifty 200"
            performaceData={chartData && chartData}
            tabName="alpha-200"
          />
           <RiskManagement/>
          <BacktestedPerformance backtested={backtested} />
          <Backtested page="ALPHA 200"/>
          <Statistics page="ALPHA 200" />
          {/* <WhyAlpha title="200" /> */}
          {/* <OtherDiscription /> */}
        </div>
        <div ref={sidebarRef} className="sidebar min-h-full w-full md:w-[40%]">
          <FixedComponent
            contentWrapperRef={contentWrapperRef}
            isAtEnd={isAtEnd}
            data={data}
            newData={newData}
          />
        </div>
      </div>
      <FooterDisclaimer />
      <GetStarted />
      {/*  <Faq />  <UpdateBox />*/}

      <Footer setShow={setShow} />
      {show === true ? <ContactUsForm setShow={setShow} show={show} /> : null}
    </div>
  );
};

export default Alpha200;
