import React from "react";
import styles from "../Faq.module.css";
const ShowTable = ({ monthlyReturns, titleOne, titleTwo }) => {
  const data2023 =
    monthlyReturns &&
    monthlyReturns.filter((row) => {
      if (row && row[0]) {
        const dateParts = row[0].split("/");
        const year = parseInt(dateParts[2]);
        return year === 2023;
      }
      return false; // Return false if row or row[0] is undefined
    });

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  // Create a Map to store the data by month
  const dataMap = new Map();

  // Iterate over the array and populate the dataMap
  data2023.forEach((row) => {
    const dateParts = row[0].split("/");
    const month = dateParts[1];
    dataMap.set(month, row);
  });

  // Create an array to hold the result
  const resultArray = [];

  // Iterate over the months array and add data to resultArray
  months.forEach((month) => {
    const rowData = dataMap.get(month);
    if (rowData) {
      resultArray.push(rowData);
    } else {
      // If data for the month is missing, add an array with empty values
      resultArray.push(["", "", ""]);
    }
  });

  const data2024 =
    monthlyReturns &&
    monthlyReturns.filter((row) => {
      if (row && row[0]) {
        const dateParts = row[0].split("/");
        const year = parseInt(dateParts[2]);
        return year === 2024;
      }
      return false; // Return false if row or row[0] is undefined
    });

  const monthsAlpha = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  // Create a Map to store the data by month
  const dataMap2 = new Map();

  // Iterate over the array and populate the dataMap
  data2024.forEach((row) => {
    const dateParts = row[0].split("/");
    const month = dateParts[1];
    dataMap2.set(month, row);
  });

  // Create an array to hold the result
  const resultAlpha = [];

  // Iterate over the months array and add data to resultArray
  monthsAlpha.forEach((month) => {
    const rowData = dataMap2.get(month);
    if (rowData) {
      resultAlpha.push(rowData);
    } else {
      // If data for the month is missing, add an array with empty values
      resultAlpha.push(["", "", ""]);
    }
  });

  // sum of all aplha 2024
  let sumAlpha2024 = 0;
  data2024.forEach((row) => {
    sumAlpha2024 += parseFloat(row[1].replace("%", ""));
  });

  // sum of all nifty 2024
  let sumNifty2024 = 0;
  data2024.forEach((row) => {
    sumNifty2024 += parseFloat(row[2].replace("%", ""));
  });

  // sum of all aplha 2023
  // let sumAlpha2023 = 0;
  // data2023.forEach((row) => {
  //   sumAlpha2023 += parseFloat(row[1].replace("%", ""));
  // });

  // sum of all nifty 2023
  // let sumNifty2023 = 0;
  // data2023.forEach((row) => {
  //   sumNifty2023 += parseFloat(row[2].replace("%", ""));
  // });
  return (
    <div>
      {monthlyReturns && monthlyReturns.length !== 0 ? (
        <div className={`mt-8 ${styles.table_container}`}>
          <table className={`${styles.tableUser1}`}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
                <th>YTD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2} className="font-semibold -rotate-90">
                  2024
                </td>
                <td className="font-semibold">{titleOne}</td>
                {resultAlpha &&
                  resultAlpha.map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={
                        row[1] &&
                        row[2] &&
                        parseFloat(row[1].replace("%", "")) >
                          parseFloat(row[2].replace("%", ""))
                          ? styles.greenColor
                          : parseFloat(row[1].replace("%", "")) <
                            parseFloat(row[2].replace("%", ""))
                          ? styles.redColor
                          : "text-center"
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 1 && cell}
                          {cellIndex === 1 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td
                  className={
                    parseFloat(sumAlpha2024) > parseFloat(sumNifty2024)
                      ? styles.greenColor
                      : styles.redColor
                  }
                >
                  {sumAlpha2024.toFixed(2)}%
                </td>
              </tr>
              <tr>
                <td className="font-semibold">{titleTwo}</td>
                {resultAlpha &&
                  resultAlpha.map((row, rowIndex) => (
                    <td key={rowIndex} className="text-center">
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 2 && cell}
                          {cellIndex === 2 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className="text-center">{sumNifty2024.toFixed(2)}%</td>
              </tr>

              {/* <tr>
                <td className="font-semibold">2023</td>
                {resultArray &&
                  resultArray.map((row, rowIndex) => (
                    <td key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}></span>
                      ))}
                    </td>
                  ))}
                <td></td>
              </tr> */}
              <tr>
                <td rowSpan={2} className="font-semibold -rotate-90">
                  2023
                </td>
                <td className="font-semibold">{titleOne}</td>
                {resultArray &&
                  resultArray.map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={
                        row[1] &&
                        row[2] &&
                        parseFloat(row[1].replace("%", "")) >
                          parseFloat(row[2].replace("%", ""))
                          ? styles.greenColor
                          : parseFloat(row[1].replace("%", "")) <
                            parseFloat(row[2].replace("%", ""))
                          ? styles.redColor
                          : "text-center"
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 1 && cell}
                          {cellIndex === 1 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className="text-center">-</td>
              </tr>
              <tr>
                <td className="font-semibold">{titleTwo}</td>
                {resultArray &&
                  resultArray.map((row, rowIndex) => (
                    <td key={rowIndex} className="text-center">
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 2 && cell}
                          {cellIndex === 2 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className="text-center">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="py-4">COMING SOON</div>
      )}
    </div>
  );
};

export default ShowTable;
