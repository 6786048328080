import React from "react";

const IndiaOptionBackTested = ({ page }) => {
  const data = [
    {
      header: "Yearly Return(%)",
      january: "Jan",
      february: "Feb",
      march: "Mar",
      april: "Apr",
      may: "May",
      june: "Jun",
      july: "Jul",
      august: "Aug",
      september: "Sep",
      october: "Oct",
      november: "Nov",
      december: "Dec",
      overAll: "Overall",
    },
    {
      header: "2021",
      january: "25%",
      february: "41%",
      march: "-5%",
      april: "1%",
      may: "1%",
      june: "-6%",
      july: "2%",
      august: "6%",
      september: "19%",
      october: "23%",
      november: "7%",
      december: "10%",
      overAll: "125%",
    },
    {
      header: "2022",
      january: "97%",
      february: "3%",
      march: "-2%",
      april: "1%",
      may: "4%",
      june: "5%",
      july: "3%",
      august: "10%",
      september: "-4%",
      october: "-2%",
      november: "-7%",
      december: "2%",
      overAll: "110%",
    },
    {
      header: "2023",
      january: "2%",
      february: "-4%",
      march: "-1%",
      april: "26%",
      may: "-1%",
      june: "-4%",
      july: "28%",
      august: "0%",
      september: "28%",
      october: "34%",
      november: "-4%",
      december: "84%",
      overAll: "189%",
    },
  ];

  return (
    <div className=" pt-[28px]">
      {/* Desktop View */}
      <div className="w-full overflow-x-scroll md:overflow-hidden">
        <table className="table-auto border-[1px] border-[#cbcbcb] w-full">
          <thead>
            <tr className="bg-white text-black">
              {data[0] &&
                Object.values(data[0]).map((header, index) => (
                  <th
                    key={index}
                    className={`p-[5px] border-[1px] font-poppins text-[14px] ${
                      index === 0 ? "text-left font-semibold" : "text-right font-semibold"
                    } border-[#cbcbcb]`}
                  >
                    {header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex} className="bg-[#ffffff] text-black">
                {Object.values(row).map((value, index) => (
                  <td
                    key={index}
                    className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                      index === 0 ? "text-left font-semibold" : "text-right"
                    }`}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IndiaOptionBackTested;
