import React, { useState, useEffect } from "react";
import animation from "../common/animation";

import Thinking from "../assests/aboutUs/Thinking.svg";
const ExpertPillers = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);

  const [state, setState] = useState("macro");
  return (
    <div className="py-12 px-2 md:px-36">
      <div className="px-2 hiddenAnimation text-black text-[32px] leading-[62px] font-bold font-rubik">
        3 Pillars of Expertise
      </div>
      <div className="px-2 hiddenAnimation text-[#000000]/80 text-[15px] leading-[25px] font-light font-rubik">
        Our investment approach combines directional, relative value and
        derivative trading strategies designed to deliver orthogonal but
        complementary return streams across a broad range of investment
        solutions.
      </div>

      <div className="flex flex-row items-center mt-6">
        <div
          className={
            state === "macro"
              ? "w-[33%] text-black text-xs md:text-xl leading-[42px] text-center font-bold font-rubik border-b-2 border-[#FFC907] cursor-pointer pb-4"
              : "w-[33%] text-[#000000]/70 text-xs md:text-xl leading-[42px] text-center font-normal font-rubik cursor-pointer pb-4"
          }
          onClick={() => setState("macro")}
        >
          Macro Thinking
        </div>
        <div
          className={
            state === "trade"
              ? "w-[33%] text-black text-xs md:text-xl leading-[42px] text-center font-bold font-rubik border-b-2 border-[#FFC907] cursor-pointer pb-4"
              : "w-[33%] text-[#000000]/70 text-xs md:text-xl leading-[42px] text-center font-normal font-rubik cursor-pointer pb-4"
          }
          onClick={() => setState("trade")}
        >
          Trade Structuring
        </div>
        <div
          className={
            state === "risk"
              ? "w-[33%] text-black text-xs md:text-xl leading-[42px] text-center font-bold font-rubik border-b-2 border-[#FFC907] cursor-pointer pb-4"
              : "w-[33%] text-[#000000]/70 text-xs md:text-xl leading-[42px] text-center font-normal font-rubik cursor-pointer pb-4"
          }
          onClick={() => setState("risk")}
        >
          Risk Management
        </div>
      </div>
      {state === "risk" ? (
        <div className="px-4 md:px-20 py-10 flex flex-col md:flex-row shadow-xl rounded-lg border-t border-[#000000]/10">
          <div className="hiddenAnimation w-full md:w-[60%] ">
            <div className="text-balck text-lg font-bold font-rubik">
              Research & Analysis
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We leverage our global network of independent and critical
              thinkers to support our research process.
            </div>
            <div className="mt-8 text-balck text-lg font-bold font-rubik">
              Debate & Challenge
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We encourage information flow and transparency throughout the
              firm, maintaining a community of creative, free-thinking
              investment professionals where original and differentiated thought
              is encouraged, and views are challenged.
            </div>
            <div className="mt-8 text-balck text-lg font-bold font-rubik">
              Non-Consensus
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We believe in the power and value of contrarian thought.
              Intellectual humility, flexibility and imagination are critical to
              long term success.
            </div>
          </div>
          <div className="hiddenAnimation mt-8 md:mt-0">
            <img src={Thinking} alt="Thinking" className="w-full" />
          </div>
        </div>
      ) : state === "trade" ? (
        <div className=" px-4 md:px-20 py-10 flex flex-col md:flex-row shadow-xl rounded-lg border-t border-[#000000]/10">
          <div className="hiddenAnimation w-full md:w-[60%] ">
            <div className="text-balck text-lg font-bold font-rubik">
              Research & Analysis
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We leverage our global network of independent and critical
              thinkers to support our research process.
            </div>
            <div className="mt-8 text-balck text-lg font-bold font-rubik">
              Debate & Challenge
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We encourage information flow and transparency throughout the
              firm, maintaining a community of creative, free-thinking
              investment professionals where original and differentiated thought
              is encouraged, and views are challenged.
            </div>
            <div className="mt-8 text-balck text-lg font-bold font-rubik">
              Non-Consensus
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We believe in the power and value of contrarian thought.
              Intellectual humility, flexibility and imagination are critical to
              long term success.
            </div>
          </div>
          <div className="hiddenAnimation mt-8 md:mt-0">
            <img src={Thinking} alt="Thinking" className="w-full" />
          </div>
        </div>
      ) : (
        <div className="px-4 md:px-20 py-10 flex flex-col md:flex-row shadow-xl rounded-lg border-t border-[#000000]/10">
          <div className="hiddenAnimation w-full md:w-[60%] ">
            <div className="text-balck text-lg font-bold font-rubik">
              Research & Analysis
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We leverage our global network of independent and critical
              thinkers to support our research process.
            </div>
            <div className="mt-8 text-balck text-lg font-bold font-rubik">
              Debate & Challenge
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We encourage information flow and transparency throughout the
              firm, maintaining a community of creative, free-thinking
              investment professionals where original and differentiated thought
              is encouraged, and views are challenged.
            </div>
            <div className="mt-8 text-balck text-lg font-bold font-rubik">
              Non-Consensus
            </div>
            <div className="mt-4 w-[90%] text-[15px] text-[#000000]/70 leading-[25px] font-rubik">
              We believe in the power and value of contrarian thought.
              Intellectual humility, flexibility and imagination are critical to
              long term success.
            </div>
          </div>
          <div className="hiddenAnimation mt-8 md:mt-0">
            <img src={Thinking} alt="Thinking" className="w-full" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpertPillers;
