import React from 'react';

const RiskManagement = () => {
  const style = {
    header: "text-[22px] font-bold py-4",
    textDescription: "mt-6 text-base text-[#000000]/80 font-rubik font-normal",
    cell: "p-4 align-top border border-gray-300", // Add border to each cell
    tableHeader: "bg-[#2D3E50] text-white font-bold p-4 border border-gray-300", // Add border to headers
    verticalLine: "border-r-2 border-gray-300", // Style for the vertical line
  };

  return (
    <div className="">
      <div className="text-black text-[22px]  leading-[30px] font-rubik font-bold mt-[60px]">
        Navigating Market Volatility: Our Risk Management
      </div>
      <table className="w-full mt-6 border-collapse border border-gray-300"> {/* Add border around the table */}
        <thead>
          <tr className="bg-[#2D3E50] text-white">
            <th className={style.tableHeader}>Key Risks</th>
            <th className={style.tableHeader}>Mitigating Strategies</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`${style.cell} ${style.verticalLine} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins ">Equity Market Risk :</span> Potential
              decrease in value due to external factors.
            </td>
            <td className={`${style.cell} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins">Stop-Loss :</span> Protects against
              sudden market downturns
            </td>
          </tr>
          <tr>
            <td className={`${style.cell} ${style.verticalLine} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins">Momentum Strategy :</span> may
              sometimes experience drawdowns in search of high momentum stocks.
            </td>
            <td className={`${style.cell} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins">Rebalancing :</span> Ensures
              consistent asset class diversification
            </td>
          </tr>
          <tr>
            <td className={`${style.cell} ${style.verticalLine} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins">Industry Concentration :</span>{" "}
              Sector-specific vulnerabilities.
            </td>
            <td className={`${style.cell} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins">Model Testing :</span> Prior
              validation for live trades
            </td>
          </tr>
          <tr>
            <td className={`${style.cell} ${style.verticalLine} ${style.textDescription} w-1/2`}>
              <span className="font-medium font-poppins">Foreign Investment Risk :</span>{" "}
              Currency fluctuations, political instability, & regulatory
              changes.
            </td>
            <td className={`${style.cell} w-1/2`}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RiskManagement;
