import React, { useEffect, useState } from 'react';

const Statistics = ({page}) => {
  const [data,setData]=useState([]);
  const [nifty,setNifty]=useState("");
  

  useEffect(()=>{
    const fun=()=>{
      if(page=="ALPHA 50"){
        setData(data1)
        setNifty("NIFTY 50")
      }
      else if(page=="ALPHA 100"){
        setData(data2)
        setNifty("NIFTY 100")
      }
      else if(page=="ALPHA 200"){
        setData(data3)
        setNifty("NIFTY 200")
      }
      else if(page=="ALPHA 500"){
        setData(data4)
        setNifty("NIFTY 500")
      }else if(page=="S&P 500"){
          setData(data5)
          setNifty("ALPHA S&P")
      }else if(page=="NDX 100"){
        setData(data6)
        setNifty("ALPHA NDX")
      }else if(page=="NIFTY 50"){
        setData(data7)
        setNifty("India Options")
      }

    }
    fun();
      
  },[page])

  const data1 = [
    { year: 'Returns', nifty: '402%', alpha: '299%'},
    { year: 'CAGR', nifty: '14.3%', alpha: '12.0%' },
    { year: 'Sharpe Ratio', nifty: '0.90', alpha: '0.78' },
    { year: 'Max Drawdown', nifty: '-24.0%', alpha: '-34.6%' },
    { year: 'Sortino Ratio', nifty: '1.41', alpha: '1.14' },
    
  ];
  const data2 = [
    { year: 'Returns', nifty: '657%',niftyOther:"299%", alpha: '312%',  },
    { year: 'CAGR', nifty: '18.20%',niftyOther:"12.0%", alpha: '12.43%',  },
    { year: 'Sharpe Ratio', nifty: '0.98',niftyOther:"0.78", alpha: '0.8',  },
    { year: 'Max Drawdown', nifty: '-23.83%',niftyOther:"-34.6%", alpha: '-33.9%',  },
    { year: 'Sortino Ratio', nifty: '1.42',niftyOther:"1.14", alpha: '1.17',  },
    
    
  ];
  const data3 = [
    { year: 'Returns', nifty: '1015%',niftyOther:"299%", alpha: '253%',  },
    { year: 'CAGR', nifty: '21.1%',niftyOther:"12.0%", alpha: '10.5%',  },
    { year: 'Sharpe Ratio', nifty: '1.01',niftyOther:"0.78", alpha: '0.69',  },
    { year: 'Max Drawdown', nifty: '-29.1%',niftyOther:"-34.6%", alpha: '-34.3%',  },
    { year: 'Sortino Ratio', nifty: '1.36',niftyOther:"1.14", alpha: '0.97',  },
    
    
  ];
  const data4 = [
    { year: 'Returns', nifty: '9341%',niftyOther:"299%", alpha: '268%',  },
    { year: 'CAGR', nifty: '43.5%',niftyOther:"12.0%", alpha: '10.9%',  },
    { year: 'Sharpe Ratio', nifty: '1.60',niftyOther:"0.78", alpha: '0.71',  },
    { year: 'Max Drawdown', nifty: '-35.0%',niftyOther:"-34.6%", alpha: '-36.0%',  },
    { year: 'Sortino Ratio', nifty: '1.79',niftyOther:"1.14", alpha: '0.98',  },
  ];

  const data5 = [
    { year: 'Returns', nifty: '1413%', alpha: '285%'},
    { year: 'CAGR', nifty: '25.2%', alpha: '12.1%' },
    { year: 'Sharpe Ratio', nifty: '1.00', alpha: '0.78' },
    { year: 'Max Drawdown', nifty: '-29.5%', alpha: '-31.8%' },
    { year: 'Sortino Ratio', nifty: '1.56', alpha: '0.97' },
    
  ];

  const data6 = [
    { year: 'Returns', nifty: '1753%', alpha: '615%'},
    { year: 'CAGR', nifty: '27.20%', alpha: '17.62%' },
    { year: 'Sharpe Ratio', nifty: '1.117', alpha: '0.955' },
    { year: 'Max Drawdown', nifty: '-35.40%', alpha: '-35.49%' },
    { year: 'Sortino Ratio', nifty: '1.40', alpha: '1.36' },
    
  ];

  const data7 = [
    { year: 'Returns', nifty: '1753%', alpha: ''},
    { year: 'CAGR', nifty: '27.20%', alpha: '' },
    { year: 'Sharpe Ratio', nifty: '1.117', alpha: '1.77' },
    { year: 'Max Drawdown', nifty: '-35.40%', alpha: '15%' },
    { year: 'Sortino Ratio', nifty: '1.40', alpha: '6' },
    
  ];

  
  return (
    <div className="">
      <div className='mt-[60px] text-black text-[22px]  leading-[30px] font-rubik font-bold pb-[28px]' >Statistics</div>
      {/* Desktop View */}
      <div className="w-full  overflow-x-scroll md:overflow-hidden">
        <table className="table-auto border-[1px] border-[#cbcbcb] w-full">
          <thead>
            <tr className="bg-white text-black">
              <th className="p-[10px] border-[1px] font-poppins text-left text-[14px] border-[#cbcbcb]">Factor</th>
              {data.slice(0, 13).map((row, index) => (
                <th key={index} className="p-[5px] border-[1px] font-poppins text-[14px] text-right border-[#cbcbcb]">
                  {row.year}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
         {page!="NIFTY 50" && <tr className="bg-[#ffffff] text-black">
              <td className="p-[10px] border-[1px] font-semibold font-poppins text-[14px] text-left border-[#cbcbcb]">{page}</td>
              {data.slice(0, 13).map((row, index) => (
                <td key={index} className="px-[3px] pr-[10px] font-poppins text-[14px] py-[10px] text-right border-[1px] border-[#cbcbcb]">
                  {row.nifty}
                </td>
              ))}
            </tr>}
          {page!="ALPHA 50" && page!="S&P 500" && page!="NDX 100" && page!="NIFTY 50" && (<tr className="bg-[#ffffff] text-black">
              <td className="px-[10px] border-[1px] pr-[10px] font-semibold font-poppins text-[14px] text-left border-[#cbcbcb]">NIFTY 50</td>
              {data.slice(0, 13).map((row, index) => (
                <td key={index} className="px-[4px] py-[10px] pr-[10px] font-poppins text-[14px] text-right border-[1px] border-[#cbcbcb]">
                  {row.niftyOther}
                </td>
              ))}
            </tr>)}
      
            <tr className="bg-[#ffffff] text-black">
              <td className="p-[10px] text-left text-[14px] pr-[10px] font-poppins border-[1px] font-semibold border-[#cbcbcb]">{nifty}</td>
              {data.slice(0, 13).map((row, index) => (
                <td
                  key={index}
                  className="px-[3px] text-[14px] py-[10px] w-[16.5%] pr-[10px] font-poppins text-right border-[1px] border-[#cbcbcb]"
                  style={{ backgroundColor: row.alphaBg }}
                >
                  {row.alpha}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Statistics;
