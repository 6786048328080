import React from "react";

const ConnectNow = () => {
  return (
    <div className="px-16 md:px-12 py-4 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[4px] hover:scale-105 cursor-pointer">
      Connect Now
    </div>
  );
};
export default ConnectNow;
