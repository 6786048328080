import React from "react";
import ContainerPoints from "../ContainerPoints";
import styles from "../PrivacyPolicy.module.css";

function ContainerEight(props) {
  return (
    <div>
      <div className={styles.Heading}>
        {props.no && props.no}.{props.heading && props.heading}
      </div>
      <span>{props.para1 && props.para1}</span>
      {props.roman1 && (
        <>
          <div style={{ margin: "25px auto 10px" }}>
            {" "}
            <span className={styles.HeadingRoman1}>
              {" "}
              {props.roman1 && props.roman1} {props.heading1 && props.heading1}
            </span>{" "}
            <span className={styles.SubHeading}>{props.headingContent1}</span>
          </div>
        </>
      )}
      {props.roman2 && (
        <>
          <div style={{ margin: "15px auto" }}>
            {" "}
            <span className={styles.HeadingRoman1}>
              {" "}
              {props.roman2 && props.roman2} {props.heading2 && props.heading2}
            </span>{" "}
            <span className={styles.SubHeading}>{props.headingContent1}</span>
          </div>
        </>
      )}

      {props.roman3 && (
        <>
          <div className={styles.HeadingRoman}>
            {" "}
            {props.roman3 && props.roman3} {props.heading3 && props.heading3}
          </div>

          <ContainerPoints
            bullet1={props.Section3bullet1}
            bullet2={props.Section3bullet2}
            bullet3={props.Section3bullet3}
            bullet4={props.Section3bullet4}
            bullet5={props.Section3bullet5}
            bullet6={props.Section3bullet6}
            bullet7={props.Section3bullet7}
          />
        </>
      )}
    </div>
  );
}

export default ContainerEight;
