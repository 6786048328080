import React from "react";
import ContainerPoints from "../ContainerPoints";
import styles from "../PrivacyPolicy.module.css";

function ContainerFour(props) {
  return (
    <div>
      <div className={styles.Heading}>
        {props.no && props.no}.{props.heading && props.heading}
      </div>
      {props.roman1 && (
        <>
          <div className={styles.HeadingRoman1}>
            {" "}
            {props.roman1 && props.roman1} {props.heading1 && props.heading1}
          </div>

          <ContainerPoints
            paraHeading1={props.paraSection1Heading1}
            bullet1={props.Section1bullet1}
            bullet2={props.Section1bullet2}
            bullet3={props.Section1bullet3}
            bullet4={props.Section1bullet4}
            bullet5={props.Section1bullet5}
            paraFooter1={props.paraSection1Footer1}
          />
        </>
      )}
      {props.roman2 && (
        <>
          <div className={styles.HeadingRoman}>
            {" "}
            {props.roman2 && props.roman2} {props.heading2 && props.heading2}
          </div>

          <ContainerPoints
            paraHeading1={props.paraSection2Heading1}
            paraHeading2={props.paraSection2Heading2}
            paraHeading3={props.paraSection2Heading3}
          />
        </>
      )}

      {props.roman3 && (
        <>
          <div className={styles.HeadingRoman}>
            {" "}
            {props.roman3 && props.roman3} {props.heading3 && props.heading3}
          </div>

          <ContainerPoints
            paraHeading1={props.paraSection3Heading1}
            paraHeading2={props.paraSection3Heading2}
            paraHeading3={props.paraSection3Heading3}
            paraHeading4={props.paraSection3Heading4}
            bullet1={props.Section3bullet1}
            bullet2={props.Section3bullet2}
            bullet3={props.Section3bullet3}
            bullet4={props.Section3bullet4}
            bullet5={props.Section3bullet5}
            paraFooter1={props.paraSection3Footer1}
          />
        </>
      )}
    </div>
  );
}

export default ContainerFour;
