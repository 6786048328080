import React from "react";
const FooterDisclaimer = () => {
  return (
    <div className=" bg-[#E6F5F8] text-base  mt-[-50px] text-[#000000]/80 font-rubik py-8 px-4 md:px-36">
      <span className="font-bold">Disclaimer :</span> Outside of the tables
      marked ‘live performance’, the numbers and graphics herein are examples
      and exhibits of the topic discussed and do not represent trading in actual
      accounts. Funds utilizing futures and derivatives carry a risk of
      substantial losses and are not suitable for all investors. There is no
      guarantee the strategies outlined herein will result in profits or achieve
      their desired outcome. Please see 
      <a href="/terms-and-conditions" className="border-b border-[#000000]/80">
        full terms of use risk disclaimer 
      </a>
      and 
      <a href="/privacy-policy" className="border-b border-[#000000]/80">
        privacy policy
      </a>
      .
    </div>
  );
};

export default FooterDisclaimer;
