import React, { useEffect, useState } from "react";

const IndiaOptionLivePerformance = ({ page }) => {
  const data = [
    {
      header: "Month",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
    },
    {
      header: "Trades",
      september: "93",
      october: "91",
      november: "65",
      december: "102",
      january: "73",
      february: "67",
      march: "39",
      april: "32",
      may: "54",
      june: "39",
      july: "54",
    },
    {
      header: "Accuracy(trades)",
      september: "42%",
      october: "43%",
      november: "28%",
      december: "42%",
      january: "44%",
      february: "31%",
      march: "26%",
      april: "31%",
      may: "51.85%",
      june: "48.71%",
      july: "29.62%",
    },
    {
      header: "PNL in %",
      september: "3.61%",
      october: "6.39%",
      november: "3.18%",
      december: "10.10%",
      january: "2.66%",
      february: "-7.49%",
      march: "-20.49%",
      april: "4.3%",
      may: "36.53%",
      june: "6.77%",
      july: "-2.8%",
    },
    {
      header: "Accuracy (days)",
      september: "50%",
      october: "44%",
      november: "29%",
      december: "33%",
      january: "32%",
      february: "28%",
      march: "15%",
      april: "35%",
      may: "47.05%",
      june: "53.84%",
      july: "21.05%",
    },
    {
      header: "Avg. Winning Day",
      september: "6%",
      october: "10%",
      november: "11%",
      december: "31%",
      january: "11%",
      february: "13%",
      march: "13%",
      april: "13%",
      may: "15.26%",
      june: "16.62%",
      july: "12.47%",
    },
    {
      header: "Avg. Losing Day",
      september: "-0.2%",
      october: "-0.15%",
      november: "-0.2%",
      december: "-0.9%",
      january: "-0.3%",
      february: "-1.3%",
      march: "-2.5%",
      april: "-0.4%",
      may: "-2.45%",
      june: "-10.79%",
      july: "-7.88%",
    },
  ];

  return (
    <div className="">
      <div className="mt-[60px] text-black text-[22px]  leading-[30px] font-rubik font-bold pb-[28px]">
        Live Performance
      </div>
      {/* Desktop View */}
      <div className="w-full  overflow-x-scroll md:overflow-hidden">
        <table className="table-auto border-[1px] border-[#cbcbcb] w-full">
          <thead>
            <tr className="bg-white text-black">
              {data.map((row, index) => (
                <th
                  key={index}
                  className="p-[5px] border-[1px] font-poppins text-[14px] text-left border-[#cbcbcb]"
                >
                  {row.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                  key={index}
                  className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                    index === 0 ? "text-left" : "text-right"
                  }`}
                >
                  {row.september}
                </td>
              ))}
            </tr>

            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.october}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.november}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.december}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.january}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.february}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.march}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.april}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.may}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.june}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              {data.map((row, index) => (
                <td
                key={index}
                className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                  index === 0 ? "text-left" : "text-right"
                }`}
                >
                  {row.july}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IndiaOptionLivePerformance;
