import React, { useEffect } from "react";

import animation from "../common/animation";

import User from "../assests/aboutUs/Building.svg";
import Vector from "../assests/aboutUs/Vector.svg";
import Building from "../assests/aboutUs/UserIcon.svg";

const data = [
  {
    imageUrl: User,
    heading: "Extraordinary People",
    desc: "Our deep and diverse talent pool is supported by a collaborative culture where we nurture talent and grow as a community of unique, creative and independent thinkers.",
  },
  {
    imageUrl: Vector,
    heading: "Best-in-Class Technology",
    desc: "Heavy investment and ongoing development ensures our team members have the best tools possible to succeed.",
  },
  {
    imageUrl: Building,
    heading: "Diversified Investment Products",
    desc: "Our broad range of diversified investment products are distinct yet complementary to one another and solve for a wide range of portfolio needs.",
  },
  {
    imageUrl: User,
    heading: "Outstanding Macro Analysis",
    desc: "Our macro analysis & research is our critical differentiator, driving our investment philosophy regardless of investment strategy.",
  },
  {
    imageUrl: Vector,
    heading: "Structuring Expertise",
    desc: "We leverage our structuring expertise to optimise the investment outcomes of our research ideas.",
  },
  {
    imageUrl: Building,
    heading: "Excellence in Risk Management",
    desc: "Extensive use and continual development of stress testing allows us to examine tail risks and mitigate potential loss scenarios.",
  },
];
const HallMarks = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className={`bg-[#243444] py-16 md:py-24 px-8 md:px-36`}>
      <div className="hiddenAnimation flex flex-row justify-center text-white text-[32px] leading-[30px] font-rubik font-bold">
        HallMarks of Alpha
      </div>
      <div className="hiddenAnimation grid grid-cols-1 md:grid-cols-3 gap-12 mt-20">
        {data.map((ele, i) => {
          return (
            <div key={i} className="items-center">
              <div>
                <img src={ele.imageUrl} alt="User Icons" />
              </div>
              <div className="mt-3 text-lg text-[#ffffff]/80 leading-[30px] font-bold font-rubik">
                {ele.heading}
              </div>
              <div className="mt-2 text-sm text-[#ffffff]/50 leading-[23px] font-rubik">
                {ele.desc}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HallMarks;
