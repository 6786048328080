import React from "react";
import styles from "./PrivacyPolicy.module.css";

function Container(props) {
  return (
    <div
      className={props.no === "1" ? styles.sectionmain : styles.sectionmain1}
    >
      {(props.no || props.heading) && (
        <div className={styles.Heading}>
          {props.no}
          {props.no ? "." : ""} {""}
          {props.heading}
        </div>
      )}
      {props.para ? (
        <div className={styles.SubHeading}>
          <p>{props.para1 && props.para1}</p>
          <p>{props.para2 && props.para2}</p>
          <p>{props.para3 && props.para3}</p>
          <p>{props.para4 && props.para4}</p>
          <p>{props.para5 && props.para5}</p>
        </div>
      ) : (
        <div className={styles.SubHeading}>
          {props.terms && props.terms === "true" ? (
            <span>
              Welcome to <strong>SecondStreet Fund PCC</strong>
              (“Company”, “we”, “our”, “us”)!",
            </span>
          ) : props.terms2 && props.terms2 === "true" ? (
            <span>
              By using our Service, you agree to subscribe to newsletters,
              marketing or promotional materials and other information we may
              send. However, you may opt out of receiving any, or all, of these
              communications from us by following the unsubscribe link or by
              emailing at{" "}
              <a href={`mailto:abhimanyu@secondstreet.fund `}>abhimanyu@secondstreet.fund .</a>
            </span>
          ) : props.terms5 && props.terms5 === "true" ? (
            <span>
              We issue refunds for Contracts within <strong>30 days</strong> of
              the original purchase of the Contract.
            </span>
          ) : props.paraContact === "true" ? (
            <p>
              {" "}
              Please send your feedback, comments, requests for technical
              support by email:{""}
              <a href={`mailto:abhimanyu@secondstreet.fund `}>abhimanyu@secondstreet.fund .</a>
            </p>
          ) : (
            <span>{props.para1 && props.para1}</span>
          )}
          {props.terms5 && props.terms5 === "true" ? (
            <span>
              These Terms of Service (“Terms”, “Terms of Service”) govern your
              use of our website located at <strong>secondstreet.fund</strong> (together
              or individually “Service”) operated by SecondStreet Fund PCC.
            </span>
          ) : (
            <span>{props.para2 && props.para2}</span>
          )}

          <span>{props.para3 && props.para3}</span>
          <span>{props.para4 && props.para4}</span>
          <span>{props.para5 && props.para5}</span>
          <span>{props.para6 && props.para6}</span>
          <span>{props.para7 && props.para7}</span>
          <span>{props.para8 && props.para8}</span>
        </div>
      )}
    </div>
  );
}

export default Container;
