import React, { useEffect } from "react";
import animation from "../common/animation";

import Laurent from "../assests/aboutUs/Laurent.svg";
import Comma from "../assests/aboutUs/comma.svg";

const Review = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className="bg-[#E6F5F8] py-24 px-4 md:px-36">
      <div className="flex flex-col lg:flex-row ">
        <div className="hiddenAnimation w-full h-[450px] lg:h-auto lg:w-[30%]  overflow-hidden">
          <img
            src={Laurent}
            alt="User"
            className="w-full rounded-[20px]  lg:rounded-none"
          />
        </div>
        <div className="flex flex-col  justify-between w-full lg:w-[70%] px-4 md:px-12">
          <div>
            <div className="hiddenAnimation w-full md:w-[95%] text-[14px] leading-[28px] md:text-[16px] md:leading-[30px] text-[#000000]/80 font-rubik">
              Active managers who want to survive have to up their game. That
              means better risk control, better stop-loss policy, better bet
              sizing, better execution, better emotion control. In a nutshell,
              that means better process. If investing is a process, then
              automation is the logical conclusion. Discretionary investors will
              gravitate towards algorithms to remain competitive.
            </div>
            <div className="hiddenAnimation w-full md:w-[95%] text-[14px] leading-[28px] md:text-[16px] md:leading-[30px] text-[#000000]/80 font-poppins mt-8">
              Even if they choose not to automate their systems, discretionary
              traders should formalise their process to the point of being
              computer coded. This sheds light on the blind spots in their
              process.
            </div>
          </div>
          <div className="hiddenAnimation flex flex-row justify-between items-center mt-8">
            <div>
              <div className="text-md md:text-xl font-rubik text-black font-bold mb-1.5">
                - Laurent Bernut
              </div>
              <div className=" text-[12px] md:text-base font-rubik text-[#000000]/70">
                ( CEO - ASC Capital, Ex- fidelity, HSBC )
              </div>
            </div>
            <div>
              <img src={Comma} alt="Quote" className="w-[50px] md:w-[80px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
