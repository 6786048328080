import React, { useEffect } from "react";
import ConnectNow from "../../common/ConnectNowButton";

import First from "../../assests/WhyUs/StrategicFocus.png";
import Second from "../../assests/WhyUs/LongOnlyApproch.png";
import Third from "../../assests/WhyUs/Seamless.png";
import Fourth from "../../assests/WhyUs/Consistent.png";
import Fifth from "../../assests/WhyUs/Risk.png";
import Six from "../../assests/WhyUs/Automated.png";

import animation from "../../common/animation";
import EnsurityFlexibility from "../../common/EnsurityFlexibility";
const data = [
  {
    heading: "Strategic Expertise",
    desc: "leverage systematic quant-based strategies to optimize investment outcomes, minimizing human biases and maximizing consistency.",
    imgUrl: First,
    tag: "Strategic Expertise",
  },
  {
    heading: "Innovative Algorithms",
    desc: "identify high-quality stocks and optimize portfolio performance using momentum strength and relative value indicators.",
    imgUrl: Second,
    tag: "Innovative Algorithms",
  },
  {
    heading: "Proven Track Record",
    desc: "Consistently outperforming benchmark indices and delivering superior risk-adjusted returns.",
    imgUrl: Third,
    tag: "Proven Track Record",
  },
  {
    heading: "Dynamic Rebalancing",
    desc: "sophisticated rebalancing process to adapt to changing market conditions",
    imgUrl: Fourth,
    tag: "Dynamic Rebalancing",
  },
  {
    heading: "Comprehensive Risk Management",
    desc: "Dedicated approach to risk management and regular rebalancing reducing risk of investments.",
    imgUrl: Fifth,
    tag: "Comprehensive Risk Management",
  },
  {
    heading: "Accessible and Flexible",
    desc: "High liquidity investment options with no lock-up periods, allowing investors flexibility.",
    imgUrl: Six,
    tag: "Accessible and Flexible",
  },
];
const WhyUs = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <>
    <div className="py-20 px-4 md:px-36 bg-[#87CEEB]/30" id="why-us">
      <div className="hiddenAnimation text-[#211f54] text-2xl md:text-[38px] md:leading-[62px] text-center font-bold font-sans">
        {" "}
        Why Second Street ?
      </div>
      <div className="hiddenAnimation text-center text-sm leading-[24px] md:text-lg text-[#000000] mt-2">
        Unparalleled expertise, unwavering commitment, and a track <br />
        record of delivering exceptional results.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12">
        {data.map((ele, i) => {
          return (
            <div
              className="hiddenAnimation flex flex-col md:flex-row bg-white px-6 md:px-8 py-8 md:py-8 rounded-[20px]"
              key={i}
            >
              <div>
                <img src={ele.imgUrl} alt={ele.tag} width={120} />
              </div>
              <div className="md:ml-6 mt-4 md:mt-0">
                <div className="text-xl md:text-xl font-bold text-[#211f54] font-sans">
                  {ele.heading}
                </div>
                <div className="text-sm md:text-base text-[#211f54]/90 font-poppins mt-3">
                  {ele.desc}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="hiddenAnimation flex flex-row justify-center mt-16">
        <ConnectNow />
      </div>
    </div>
    <EnsurityFlexibility/>
    </>
  );
};

export default WhyUs;
