import React from "react";

const style = {
  textDescription:
    " mt-6 text-base text-[#000000]/80 font-rubik font-normal",
  textDescriptionNormal:
    " text-base text-[#000000]/80 font-rubik font-normal",
};
const BacktestedPerformance = ({ backtested }) => {
  return (
    <div>
      <div className=" mt-[60px] text-black text-[22px]  leading-[30px] font-rubik font-bold ">
        Backtested Performance
      </div>
      <div className={style.textDescription}>
        Full performance tables including backtested results prior to the fund
        launch are available to QEPs. Please review important disclosures
        regarding the material assumptions and hypothetical nature of the
        backtested results when viewing this performance.*
      </div>
      <div className={style.textDescription}>
        *This presentation is intended only for investors with more than $2
        million in investable assets that qualify as a “QEP” (learn more).
        Please note that this requirement is only to view backtested results,
        and that the investment itself is available to all accredited investors.
      </div>

      {/* <div className=" mt-6 border border-[#000000]/10 md:flex hidden flex-row items-center justify-between rounded-[4px] ">
        {backtested.map((ele, i) => {
          return (
            <div
              key={i}
              className="px-12 flex flex-row items-center py-3 border-b md:border-b-0 md:border-r border-[#000000]/10"
            >
              <img src={ele.imageUrl} alt="Pdf" />
              <div className="text-base ml-3 text-[#000000]/70 font-rubik">
                {ele.name}
              </div>
            </div>
          );
        })}
      </div> */}
      {/* <div className=" mt-6 border border-[#000000]/10 md:hidden items-center rounded-[4px] ">
        {backtested.map((ele, i) => {
          return (
            <div
              key={i}
              className="px-8 flex flex-row items-center py-3 border-b border-[#000000]/10"
            >
              <img src={ele.imageUrl} alt="Pdf" />
              <div className="text-base ml-3 text-[#000000]/70 font-rubik">
                {ele.name}
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default BacktestedPerformance;
