import React from "react";
import ShowTable from "./Performace/ShowTable";
import ChartPlotting from "./Performace/ChartPlotting";
import DownloadReport from "./Performace/DownloadReport";
const LivePerformace = ({
  monthlyReturns,
  titleOne,
  titleTwo,
  performaceData,
  tabName,
}) => {
  return (
    <div className="relative">
      <div>
        <div className=" mt-[60px] text-black text-[22px]  leading-[30px] font-rubik font-bold ">
          Live Performance
        </div>
        {/* <div className=" mt-2 text-sm font-rubik text-[#000000]/70">
          Past performance is not necessarily indicative of future results.
        </div> */}
      </div>

      {performaceData && performaceData.length !== 0 ? (
        <div className="absolute right-0 top-0">
          <DownloadReport tabName={tabName} />
        </div>
      ) : null}
      {performaceData && performaceData.length !== 0 ? (
        <ChartPlotting
          chartData={performaceData ? performaceData : []}
          titleOne={titleOne}
          titleTwo={titleTwo}
        />
      ) : null}

      <ShowTable
        monthlyReturns={monthlyReturns && monthlyReturns}
        titleOne={titleOne}
        titleTwo={titleTwo}
      />

      {/* <div className="mt-2 text-sm font-rubik text-[#000000]/70">
        The above results are the net of all fees returns for Class B in the{" "}
        {titleOne}.
      </div> */}
    </div>
  );
};

export default LivePerformace;
