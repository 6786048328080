import React from "react";
import { PrivacyData } from "./Data";
import styles from "./PrivacyPolicy.module.css";
import Container from "./Container";
import ContainerPoints from "./ContainerPoints";
import ContainerFour from "./ContainerMix/ContainerFour";
import ContainerEight from "./ContainerMix/ContainerEight";
import ContainerTwelve from "./ContainerMix/ContainerTwelve";
import StrategyNavbar from "../Navbar/StrategyNavbar";
import Footer from "../Footer/Footer";
function PrivacyPolicy() {
  return (
    <div>
      <StrategyNavbar />
      <div className={styles.containermain}>
        <div className={`${styles.Heading} text-center`}>Privacy Policy</div>
        {PrivacyData.map((ele, index) => (
          <div key={index}>
            <Container
              heading={ele.sectionOne.heading}
              no={ele.sectionOne.no}
              para1={ele.sectionOne.para1}
              para2={ele.sectionOne.para2}
              para3={ele.sectionOne.para3}
              para4={ele.sectionOne.para4}
              para5={ele.sectionOne.para5}
            />
            <Container
              heading={ele.sectionTwo.heading}
              no={ele.sectionTwo.no}
              para1={ele.sectionTwo.para1}
              para2={ele.sectionTwo.para2}
              para3={ele.sectionTwo.para3}
              para4={ele.sectionTwo.para4}
              para5={ele.sectionTwo.para5}
              para6={ele.sectionTwo.para6}
              para7={ele.sectionTwo.para7}
              para8={ele.sectionTwo.para8}
            />
            <Container
              heading={ele.sectionThree.heading}
              no={ele.sectionThree.no}
              para1={ele.sectionThree.para1}
            />
            <ContainerFour
              heading={ele.sectionFour.heading}
              no={ele.sectionFour.no}
              roman1={ele.sectionFour.roman1}
              roman2={ele.sectionFour.roman2}
              roman3={ele.sectionFour.roman3}
              heading1={ele.sectionFour.heading1}
              heading2={ele.sectionFour.heading2}
              heading3={ele.sectionFour.heading3}
              paraSection1Heading1={ele.sectionFour.paraSection1Heading1}
              paraSection2Heading1={ele.sectionFour.paraSection2Heading1}
              paraSection2Heading2={ele.sectionFour.paraSection2Heading2}
              paraSection2Heading3={ele.sectionFour.paraSection2Heading3}
              paraSection3Heading1={ele.sectionFour.paraSection3Heading1}
              paraSection3Heading2={ele.sectionFour.paraSection3Heading2}
              paraSection3Heading3={ele.sectionFour.paraSection3Heading3}
              paraSection3Heading4={ele.sectionFour.paraSection3Heading4}
              paraSection1Footer1={ele.sectionFour.paraSection1Footer1}
              paraSection3Footer1={ele.sectionFour.paraSection3Footer1}
              Section1bullet1={ele.sectionFour.Section1bullet1}
              Section1bullet2={ele.sectionFour.Section1bullet2}
              Section1bullet3={ele.sectionFour.Section1bullet3}
              Section1bullet4={ele.sectionFour.Section1bullet4}
              Section1bullet5={ele.sectionFour.Section1bullet5}
              Section3bullet1={ele.sectionFour.Section3bullet1}
              Section3bullet2={ele.sectionFour.Section3bullet2}
              Section3bullet3={ele.sectionFour.Section3bullet3}
              Section3bullet4={ele.sectionFour.Section3bullet4}
              Section3bullet5={ele.sectionFour.Section3bullet5}
            />
            <ContainerPoints
              heading={ele.sectionFive.heading}
              no={ele.sectionFive.no}
              paraHeading1={ele.sectionFive.paraHeading1}
              bullet1={ele.sectionFive.bullet1}
              bullet2={ele.sectionFive.bullet2}
              bullet3={ele.sectionFive.bullet3}
              bullet4={ele.sectionFive.bullet4}
              bullet5={ele.sectionFive.bullet5}
              bullet6={ele.sectionFive.bullet6}
              bullet7={ele.sectionFive.bullet7}
              bullet8={ele.sectionFive.bullet8}
              bullet9={ele.sectionFive.bullet9}
              bullet10={ele.sectionFive.bullet10}
              bullet11={ele.sectionFive.bullet11}
              bullet12={ele.sectionFive.bullet12}
              bullet13={ele.sectionFive.bullet13}
            />
            <Container
              heading={ele.sectionSix.heading}
              no={ele.sectionSix.no}
              para1={ele.sectionSix.para1}
            />
            <Container
              heading={ele.sectionSeven.heading}
              no={ele.sectionSeven.no}
              para1={ele.sectionSeven.para1}
            />
            <ContainerEight
              heading={ele.sectionEight.heading}
              no={ele.sectionEight.no}
              para1={ele.sectionEight.para1}
              roman1={ele.sectionEight.roman1}
              roman2={ele.sectionEight.roman2}
              roman3={ele.sectionEight.roman3}
              heading1={ele.sectionEight.heading1}
              heading2={ele.sectionEight.heading2}
              heading3={ele.sectionEight.heading3}
              headingContent1={ele.sectionEight.headingContent1}
              headingContent2={ele.sectionEight.headingContent2}
              Section3bullet1={ele.sectionEight.Section3bullet1}
              Section3bullet2={ele.sectionEight.Section3bullet2}
              Section3bullet3={ele.sectionEight.Section3bullet3}
              Section3bullet4={ele.sectionEight.Section3bullet4}
              Section3bullet5={ele.sectionEight.Section3bullet5}
              Section3bullet6={ele.sectionEight.Section3bullet6}
              Section3bullet7={ele.sectionEight.Section3bullet7}
            />
            <Container
              heading={ele.sectionNine.heading}
              no={ele.sectionNine.no}
              para1={ele.sectionNine.para1}
            />
            <ContainerPoints
              heading={ele.sectionTen.heading}
              no={ele.sectionTen.no}
              paraHeading1={ele.sectionTen.paraHeading1}
              paraHeading2={ele.sectionTen.paraHeading2}
              paraHeading3={ele.sectionTen.paraHeading3}
              paraHeading4={ele.sectionTen.paraHeading4}
              bullet1={ele.sectionTen.bullet1}
              bullet2={ele.sectionTen.bullet2}
              bullet3={ele.sectionTen.bullet3}
              bullet4={ele.sectionTen.bullet4}
              bullet5={ele.sectionTen.bullet5}
              bullet6={ele.sectionTen.bullet6}
              paraFooter1={ele.sectionTen.paraFooter1}
              paraFooter2={ele.sectionTen.paraFooter2}
            />
            <ContainerPoints
              heading={ele.sectionEleven.heading}
              no={ele.sectionEleven.no}
              paraHeading1={ele.sectionEleven.paraHeading1}
              paraHeading2={ele.sectionEleven.paraHeading2}
              bullet1={ele.sectionEleven.bullet1}
              bullet2={ele.sectionEleven.bullet2}
              bullet3={ele.sectionEleven.bullet3}
              bullet4={ele.sectionEleven.bullet4}
              paraFooter1={ele.sectionEleven.paraFooter1}
              paraFooter2={ele.sectionEleven.paraFooter2}
              paraFooter10={ele.sectionEleven.paraFooter10}
            />
            <ContainerTwelve
              heading={ele.sectionTwelve.heading}
              no={ele.sectionTwelve.no}
              roman1={ele.sectionTwelve.roman1}
              roman2={ele.sectionTwelve.roman2}
              roman3={ele.sectionTwelve.roman3}
              heading1={ele.sectionTwelve.heading1}
              heading2={ele.sectionTwelve.heading2}
              heading3={ele.sectionTwelve.heading3}
              para1={ele.sectionTwelve.para1}
              para2={ele.sectionTwelve.para2}
              para3={ele.sectionTwelve.para3}
              para4={ele.sectionTwelve.para4}
              paraSection1Footer1={ele.sectionTwelve.paraSection1Footer1}
              Section1bullet1={ele.sectionTwelve.Section1bullet1}
              Section1bullet2={ele.sectionTwelve.Section1bullet2}
              Section1bullet3={ele.sectionTwelve.Section1bullet3}
              Section1bullet4={ele.sectionTwelve.Section1bullet4}
              Section1bullet5={ele.sectionTwelve.Section1bullet5}
              Section1bullet6={ele.sectionTwelve.Section1bullet6}
            />
            <Container
              heading={ele.sectionThirteen.heading}
              no={ele.sectionThirteen.no}
              para={ele.sectionThirteen.para}
              para1={ele.sectionThirteen.para1}
              para2={ele.sectionThirteen.para2}
            />
            <Container
              heading={ele.sectionFourteen.heading}
              no={ele.sectionFourteen.no}
              para1={ele.sectionFourteen.para1}
            />

            <Container
              heading={ele.sectionSixteen.heading}
              no={ele.sectionSixteen.no}
              para1={ele.sectionSixteen.para1}
            />
            <Container
              heading={ele.sectionSeventeen.heading}
              no={ele.sectionSeventeen.no}
              para1={ele.sectionSeventeen.para1}
              para2={ele.sectionSeventeen.para2}
              para3={ele.sectionSeventeen.para3}
              para={ele.sectionSeventeen.para}
            />
            <Container
              heading={ele.sectionEightteen.heading}
              no={ele.sectionEightteen.no}
              para1={ele.sectionEightteen.para1}
              para2={ele.sectionEightteen.para2}
            />
            <Container
              heading={ele.sectionNineteen.heading}
              no={ele.sectionNineteen.no}
              para1={ele.sectionNineteen.para1}
              para2={ele.sectionNineteen.para2}
              para3={ele.sectionNineteen.para3}
            />
            <Container
              heading={ele.sectionTwenty.heading}
              no={ele.sectionTwenty.no}
              para1={ele.sectionTwenty.para1}
              paraContact="true"
            />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
