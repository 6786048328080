import React, { useEffect, useState } from 'react';

const Backtested = ({page}) => {
  const [data,setData]=useState([]);
  const [nifty,setNifty]=useState("");
  

  useEffect(()=>{
    const fun=()=>{
      if(page=="ALPHA 50"){
        setData(data1)
        setNifty("NIFTY 50")
      }
      else if(page=="ALPHA 100"){
        setData(data2)
        setNifty("NIFTY 100")
      }
      else if(page=="ALPHA 200"){
        setData(data3)
        setNifty("NIFTY 200")
      }
      else if(page=="ALPHA 500"){
        setData(data4)
        setNifty("NIFTY 500")
      }
    }
    fun();
    console.log("data",data)
      
  },[page])

  const data1 = [
    { year: '2011', niftyOther: '-24.6%', alpha: '-0.8%', alphaBg: '#B9E7C5' },
    { year: '2012', niftyOther: '27.7%', alpha: '22.0%', alphaBg: '#FAC7C1' },
    { year: '2013', niftyOther: '6.8%', alpha: '13.8%', alphaBg: '#B9E7C5' },
    { year: '2014', niftyOther: '31.4%', alpha: '20.8%', alphaBg: '#FAC7C1' },
    { year: '2015', niftyOther: '-4.1%', alpha: '-6.8%', alphaBg: '#FAC7C1' },
    { year: '2016', niftyOther: '3.0%', alpha: '0.0%', alphaBg: '#FAC7C1' },
    { year: '2017', niftyOther: '28.7%', alpha: '33.5%', alphaBg: '#B9E7C5' },
    { year: '2018', niftyOther: '3.2%', alpha: '4.6%', alphaBg: '#B9E7C5' },
    { year: '2019', niftyOther: '12.0%', alpha: '14.5%', alphaBg: '#B9E7C5' },
    { year: '2020', niftyOther: '14.9%', alpha: '39.0%', alphaBg: '#B9E7C5' },
    { year: '2021', niftyOther: '24.1%', alpha: '44.5%', alphaBg: '#B9E7C5' },
    { year: '2022', niftyOther: '4.3%', alpha: '-9.1%', alphaBg: '#FAC7C1' },
    { year: '2023', niftyOther: '9.4%', alpha: '11.0%', alphaBg: '#B9E7C5' },
  ];
  const data2 = [
    { year: '2011', nifty: '-24.6%',niftyOther:"-25.81%", alpha: '-2.97%', alphaBg: '#B9E7C5' },
    { year: '2012', nifty: '27.7%',niftyOther:"30.60%", alpha: '38.53%', alphaBg: '#B9E7C5' },
    { year: '2013', nifty: '6.8%',niftyOther:"6.46%", alpha: '23.77%', alphaBg: '#B9E7C5' },
    { year: '2014', nifty: '31.4%',niftyOther:"33.0%", alpha: '33.49%', alphaBg: '#B9E7C5' },
    { year: '2015', nifty: '-4.1%',niftyOther:"-2.41%", alpha: '14.28%', alphaBg: '#B9E7C5' },
    { year: '2016', nifty: '3.0%',niftyOther:"3.60%", alpha: '-3.18%', alphaBg: '#FAC7C1' },
    { year: '2017', nifty: '28.7%',niftyOther:"31.05%", alpha: '38.76%', alphaBg: '#B9E7C5' },
    { year: '2018', nifty: '3.2%',niftyOther:"1.14%", alpha: '6.69%', alphaBg: '#B9E7C5' },
    { year: '2019', nifty: '12.0%',niftyOther:"10.40%", alpha: '10.44%', alphaBg: '#B9E7C5' },
    { year: '2020', nifty: '14.9%',niftyOther:"14.80%", alpha: '19.96%', alphaBg: '#B9E7C5' },
    { year: '2021', nifty: '24.1%',niftyOther:"25.40%", alpha: '39.37%', alphaBg: '#B9E7C5' },
    { year: '2022', nifty: '4.3%',niftyOther:"3.60%", alpha: '5.68%', alphaBg: '#B9E7C5' },
    { year: '2023', nifty: '9.4%',niftyOther:"8.30%", alpha: '6.66%', alphaBg: '#FAC7C1' },
    
  ];

  const data3 = [
    { year: '2011', nifty: '-24.6%',niftyOther:"-27.0%", alpha: '-3.0%', alphaBg: '#B9E7C5' },
    { year: '2012', nifty: '27.7%',niftyOther:"31.60%", alpha: '34.8%', alphaBg: '#B9E7C5' },
    { year: '2013', nifty: '6.8%',niftyOther:"4.4%", alpha: '15.3%', alphaBg: '#B9E7C5' },
    { year: '2014', nifty: '31.4%',niftyOther:"35.5%", alpha: '69.5%', alphaBg: '#B9E7C5' },
    { year: '2015', nifty: '-4.1%',niftyOther:"-1.9%", alpha: '3.8%', alphaBg: '#B9E7C5' },
    { year: '2016', nifty: '3.0%',niftyOther:"3.7%", alpha: '-13.3%', alphaBg: '#FAC7C1' },
    { year: '2017', nifty: '28.7%',niftyOther:"33.4%", alpha: '37.5%', alphaBg: '#B9E7C5' },
    { year: '2018', nifty: '3.2%',niftyOther:"-1.0%", alpha: '-14.3%', alphaBg: '#FAC7C1' },
    { year: '2019', nifty: '12.0%',niftyOther:"8.6%", alpha: '7.6%', alphaBg: '#FAC7C1' },
    { year: '2020', nifty: '14.9%',niftyOther:"15.6%", alpha: '52.4%', alphaBg: '#B9E7C5' },
    { year: '2021', nifty: '24.1%',niftyOther:"27.4%", alpha: '92.0%', alphaBg: '#B9E7C5' },
    { year: '2022', nifty: '4.3%',niftyOther:"3.6%", alpha: '14.3%', alphaBg: '#B9E7C5' },
    { year: '2023', nifty: '9.4%',niftyOther:"11.5%", alpha: '15.0%', alphaBg: '#B9E7C5' },
    
  ];

  const data4 = [
    { year: '2011', nifty: '-24.6%',niftyOther:"-27.0%", alpha: '-9.44%', alphaBg: '#B9E7C5' },
    { year: '2012', nifty: '27.7%',niftyOther:"31.80%", alpha: '39.56%', alphaBg: '#B9E7C5' },
    { year: '2013', nifty: '6.8%',niftyOther:"3.6%", alpha: '2.00%', alphaBg: '#FAC7C1' },
    { year: '2014', nifty: '31.4%',niftyOther:"37.8%", alpha: '100.64%', alphaBg: '#B9E7C5' },
    { year: '2015', nifty: '-4.1%',niftyOther:"-0.7%", alpha: '16.04%', alphaBg: '#B9E7C5' },
    { year: '2016', nifty: '3.0%',niftyOther:"3.8%", alpha: '-5.26%', alphaBg: '#FAC7C1' },
    { year: '2017', nifty: '28.7%',niftyOther:"35.9%", alpha: '112.68%', alphaBg: '#B9E7C5' },
    { year: '2018', nifty: '3.2%',niftyOther:"-3.0%", alpha: '-11.71%', alphaBg: '#FAC7C1' },
    { year: '2019', nifty: '12.0%',niftyOther:"7.6%", alpha: '9.09%', alphaBg: '#B9E7C5' },
    { year: '2020', nifty: '14.9%',niftyOther:"16.6%", alpha: '87.29%', alphaBg: '#B9E7C5' },
    { year: '2021', nifty: '24.1%',niftyOther:"30.1%", alpha: '97.93%', alphaBg: '#B9E7C5' },
    { year: '2022', nifty: '4.3%',niftyOther:"3.2%", alpha: '3.48%', alphaBg: '#B9E7C5' },
    { year: '2023', nifty: '9.4%',niftyOther:"13.8%", alpha: '41.95%', alphaBg: '#B9E7C5' },
    
  ];

  return (
    <div className="pt-[38px]">
      
      {/* Desktop View */}
      <div className="w-full  overflow-x-scroll md:overflow-hidden">
        <table className="table-auto border-[1px] border-[#cbcbcb] w-full">
          <thead>
            <tr className="bg-white text-black">
              <th className="p-[10px] border-[1px] font-poppins text-left text-[14px] border-[#cbcbcb]">Year</th>
              {data.slice(0, 13).map((row, index) => (
                <th key={index} className="p-[5px] border-[1px] font-poppins text-[14px] text-right border-[#cbcbcb]">
                  {row.year}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {page!="ALPHA 50" && (<tr className="bg-[#ffffff] text-black">
              <td className="px-[10px] border-[1px] font-semibold font-poppins text-[14px] text-left border-[#cbcbcb]">NIFTY</td>
              {data.slice(0, 13).map((row, index) => (
                <td key={index} className="px-[4px] py-[10px] font-poppins text-[14px] text-right border-[1px] border-[#cbcbcb]">
                  {row.nifty}
                </td>
              ))}
            </tr>)}
            <tr className="bg-[#ffffff] text-black">
              <td className="p-[10px] border-[1px] font-semibold font-poppins text-[14px] text-left border-[#cbcbcb]">{nifty}</td>
              {data.slice(0, 13).map((row, index) => (
                <td key={index} className="px-[3px] font-poppins text-[14px] py-[10px] text-right border-[1px] border-[#cbcbcb]">
                  {row.niftyOther}
                </td>
              ))}
            </tr>
      
            <tr className="bg-[#ffffff] text-black">
              <td className="p-[10px] text-left text-[14px] font-poppins border-[1px] font-semibold border-[#cbcbcb]">{page}</td>
              {data.slice(0, 13).map((row, index) => (
                <td
                  key={index}
                  className="px-[3px] text-[14px] py-[10px] font-poppins text-right border-[1px] border-[#cbcbcb]"
                  style={{ backgroundColor: row.alphaBg }}
                >
                  {row.alpha}
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        {/* <table className="table-auto border-[1px] border-gray-300 w-full mt-8">
          <thead>
            
            <tr className="bg-[#ffffff] text-black">
              <th className="p-4 border-[1px] border-[#959595]">YEAR</th>
              {data.slice(7).map((row, index) => (
                <th key={index} className="p-4 border-[1px] border-[#959595]">
                  {row.year}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {page!="ALPHA 50" && (<tr className="bg-[#ffffff] text-black">
              <td className="p-4 border-[1px] font-semibold text-center border-[#959595]">NIFTY</td>
              {data.slice(7).map((row, index) => (
                <td key={index} className="p-4 border-[1px] border-[#959595]">
                  {row.nifty}
                </td>
              ))}
            </tr>)}
            <tr className="bg-[#ffffff] text-black">
              <td className="p-4 border-[1px] font-semibold text-center border-[#959595]">{nifty}</td>
              {data.slice(7).map((row, index) => (
                <td key={index} className="p-4 border-[1px] border-[#959595]">
                  {row.niftyOther}
                </td>
              ))}
            </tr>
            <tr className="bg-[#ffffff] text-black">
              <td className="p-4 border-[1px] font-semibold text-center border-[#959595]">{page}</td>
              {data.slice(7).map((row, index) => (
                <td
                  key={index}
                  className="p-4 border-[1px] border-[#959595]"
                  style={{ backgroundColor: row.alphaBg }}
                >
                  {row.alpha}
                </td>
              ))}
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
};

export default Backtested;
