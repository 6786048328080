import React, { useEffect } from "react";
import Logo from "../assests/logo.svg";
import animation from "../common/animation";
import facebbok from "../assests/Icon/facebook.svg";
import Instagram from "../assests/Icon/instagram.svg";
import twitter from "../assests/Icon/twitter.svg";
import LinkedIn from "../assests/Icon/linkedin.svg";
const IconData = [
  // {
  //   icon: facebbok,
  //   url: "facebook.com",
  // },
  // {
  //   icon: twitter,
  //   url: "twitter.com",
  // },
  // {
  //   icon: Instagram,
  //   url: "instagram.com",
  // },
  {
    icon: LinkedIn,
    url: "https://www.linkedin.com/company/secondstreet-fund",
  },
];

const Home = [
  {
    text: "Alpha 50",
    url: "/alpha-50",
  },
  {
    text: "Alpha 100",
    url: "/alpha-100",
  },
  {
    text: "Alpha 200",
    url: "/alpha-200",
  },
  {
    text: "Alpha 500",
    url: "/alpha-500",
  },
  {
    text: "Options",
    url: "india-options",
  },
];

const Pricing1 = [
  {
    text: "Alpha NDX",
    url: "/alpha-ndx",
  },
  {
    text: "Alpha S&P",
    url: "/alpha-s&p",
  },
  {
    text: "Options",
    url: "/usa-options",
  },
];

const Pricing2 = [
  {
    text: "Why us?",
    url: "#why-us",
  },
  {
    text: "Philosophy",
    url: "#philosophy",
  },
  {
    text: "Our Team",
    url: "#our-team",
  },
];
const Footer = ({ setShow }) => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div id="footer">
      <div className="bg-[#243444] py-8 md:py-28 px-4 md:px-36">
        <div className="pb-4 border-b border-[#ffffff]/30 flex flex-col md:flex-row justify-between">
          <div className="text-center md:text-start">
            <div className="hiddenAnimation text-white text-sm md:text-lg">
              HAVE A QUESTION ?
            </div>
            <div className="hiddenAnimation text-white text-4xl md:text-6xl font-sans font-bold mt-4 md:mt-0">
              Let's Talk
            </div>
          </div>
          <div className="hiddenAnimation text-center md:text-start mt-4 md:mt-0">
            <button
              className="md:ml-8 px-12 py-4 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[4px] hover:scale-105 cursor-pointer"
              onClick={() => setShow(true)}
            >
              {" "}
              Get it Touch
            </button>
          </div>
        </div>

        <div className="py-16 border-b border-[#ffffff]/30 md:flex flex-row justify-between hidden">
          <div className="w-[30%]">
            <img src={Logo} alt="Logo" width={200} className="" />
            {/* <div className="hiddenAnimation text-[#ffffff]/70 text-base mt-8">
              Put your business in the spotlight with a playful,
              conversion-oriented.
            </div> */}
            <div className="hiddenAnimation flex flex-row mt-8">
              {IconData.map((ele, i) => {
                return (
                  <div
                    key={i}
                    className={
                      i === IconData.length - 4
                        ? "bg-white p-3 rounded-full hover:bg-[#ffc907]"
                        : " bg-white p-3 rounded-full hover:bg-[#ffc907]"
                    }
                  >
                    <a href={ele.url} target="_blank" rel="noreferrer noopener">
                      <img src={ele.icon} alt="Icom" />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <div className="hiddenAnimation text-lg text-white font-sans font-semibold">
              Strategies - India
            </div>
            {Home.map((ele, i) => {
              return (
                <div key={i} className="mt-4 hiddenAnimation">
                  <a
                    href={ele.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className=" text-[#ffffff]/60 hover:text-[#ffc907] font-semibold"
                  >
                    {ele.text}
                  </a>
                </div>
              );
            })}
          </div>
          <div>
            <div className="hiddenAnimation text-lg text-white font-sans font-semibold">
              Strategies - USA
            </div>
            {Pricing1.map((ele, i) => {
              return (
                <div key={i} className="mt-4 flex hiddenAnimation">
                  <a
                    href={ele.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className=" text-[#ffffff]/60 hover:text-[#ffc907] font-semibold"
                  >
                    {ele.text}
                  </a>
                  {ele.text=="Options" && (<p className="bg-[#FFC9071A] mt-1 bg-opacity-10 border-[.05px] drop-shadow-[4px] rounded-[5px]  border-[#FFC907] mx-2  text-[#FFC907]  px-[6px] py-[1px] h-fit w-fit font-poppins text-[9px]">
                      Coming soon...
                    </p>)}
                </div>
              );
            })}
          </div>

          <div className="">
            <a href="#home">
              <div className="hiddenAnimation text-lg text-white font-sans font-semibold">
                Home
              </div>
            </a>

            {Pricing2.map((ele, i) => {
              return (
                <div key={i} className="mt-4 hiddenAnimation">
                  <a
                    href={ele.url}
                    className=" text-[#ffffff]/60 hover:text-[#ffc907] font-semibold"
                  >
                    {ele.text}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="py-16 border-b border-[#ffffff]/30 md:hidden">
          <div className="flex flex-row justify-center text-center">
            <img src={Logo} alt="Logo" width={200} className="" />
          </div>
          {/* <div className="hiddenAnimation text-[#ffffff]/70 text-base mt-8 text-center">
            Put your business in the spotlight with a playful,
            conversion-oriented.
          </div> */}
          <div className="hiddenAnimation flex flex-row justify-center mt-8">
            {IconData.map((ele, i) => {
              return (
                <div
                  key={i}
                  className={
                    i === IconData.length - 4
                      ? "bg-white p-3 rounded-full hover:bg-[#ffc907]"
                      : "ml-3 bg-white p-3 rounded-full hover:bg-[#ffc907]"
                  }
                >
                  <a href={ele.url} target="_blank" rel="noreferrer noopener">
                    <img src={ele.icon} alt="Icom" />
                  </a>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row justify-between px-10 mt-6 ">
            <div>
              <div className="hiddenAnimation text-lg text-white font-sans font-semibold">
                Strategies - India
              </div>
              {Home.map((ele, i) => {
                return (
                  <div key={i} className="mt-4 hiddenAnimation">
                    <a
                      href={ele.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      className=" text-[#ffffff]/60 hover:text-white font-semibold"
                    >
                      {ele.text}
                    </a>
                  </div>
                );
              })}
            </div>
            <div>
              <div className="hiddenAnimation text-lg text-white font-sans font-semibold">
                Strategies - USA
              </div>
              {Pricing1.map((ele, i) => {
                return (
                  <div key={i} className="mt-4 hiddenAnimation">
                    <a
                      href={ele.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      className=" text-[#ffffff]/60 hover:text-white font-semibold"
                    >
                      {ele.text}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="px-10 mt-6">
            <div className="hiddenAnimation text-lg text-white font-sans font-semibold">
              Home
            </div>
            {Pricing2.map((ele, i) => {
              return (
                <div key={i} className="mt-4 hiddenAnimation">
                  <a
                    href={ele.url}
                    className=" text-[#ffffff]/60 hover:text-white font-semibold"
                  >
                    {ele.text}
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full max-w-screen-xl flex flex-col-reverse md:flex-row justify-between items-center  py-4">
          <div className="text-[10px] md:text-sm  text-white/60 px-0 font-light font-Raleway">
            © 2024 Copyright by By SecondStreet Fund PCC.
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row pl-4 md:pl-0 space-y-1 md:space-y-0 items-start mb-3 md:mb-0  md:space-x-8">
            <a href={"/terms-and-conditions"}>
              <div className="text-sm font-Poppins cursor-pointer leading-6 text-[#ffffff]/60 hover:scale-105 hover:text-[#ffc907] ease-linear duration-150">
                Terms & Conditions
              </div>
            </a>
            <a href={"/privacy-policy"}>
              <div className="text-sm font-Poppins cursor-pointer leading-6 text-[#ffffff]/60 hover:scale-105 hover:text-[#ffc907] ease-linear duration-150">
                Privacy Policy
              </div>
            </a>
          </div>
        </div>
        <div className="hiddenAnimation text-sm text-[#ffffff]/60 font-poppins mt-12">
          Copyright © 2024 SecondStreet Fund PCC is a registered Fund with the
          FSC and FPI Licensed with SEBI. This website is provided for
          informational purposes only, and should not be relied upon as legal,
          business, investment, or tax advice. All opinions expressed are solely
          the opinions of the authors, and do not necessarily reflect the
          opinions of SecondStreet Fund PCC, their affiliates, co-managers of
          their funds, or companies featured. Investing is risky, and you are
          reminded that futures, commodity trading, forex, volatility, options,
          derivatives , and other alternative investments are complex and carry
          a risk of substantial losses. As such, they are not suitable for all
          investors, and you should not rely on any of the information as a
          substitute for the exercise of your own skill and judgement in making
          such a decision on the appropriateness of such investments.
        </div>
        <div className="hiddenAnimation text-lg text-white font-sans mt-8">
          DISCLAIMER
        </div>
        <div className="hiddenAnimation text-sm text-[#ffffff]/60 font-poppins mt-4">
          This presentation has been prepared by the Second Street Partners fund
          for “Sophisticated investors” (as defined under Mauritius Securities
          Law). Sophisticated investors are assumed to be better informed and
          better able to access resources to protect their own interests, and
          therefore require less regulatory protection. Investors who agree to
          be treated as sophisticated investors therefore forgo the benefit of
          certain regulatory safeguards. A “sophisticated investor” includes
          governmental, statutory corporations, and state-owned companies in
          Mauritius, government and governmental agencies of any foreign
          country, banks, insurance companies, fund managers, investment
          managers, investment advisers, and dealers. You should consult a
          professional adviser if you do not understand any consequences of
          being treated as a sophisticated investor.
        </div>
        <div className="hiddenAnimation text-sm text-[#ffffff]/60 font-poppins mt-4">
          The presentation is provided on a general basis for information
          purposes only and does not constitute an invitation, recommendation,
          offer, or solicitation to acquire, purchase, or subscribe to any funds
          managed by Second Street Partners. Any offer or solicitation will be
          made only upon execution of the completed information memorandum,
          subscription application, and relevant documentation, all of which
          must be read in their entirety. The information contained herein is
          not to be relied on as investment, legal, tax, or other advice as it
          does not take into account the investment objectives, financial
          situation, or particular needs of any specific investor. Investments
          in funds are subject to investment risks, including the possible loss
          of the principal amount invested. The value of units and shares and
          the income from them may fall or rise and investors may not get back
          the amount originally invested. Currency exchange rate changes may
          cause the value of overseas investments to rise or fall. Past
          performance is not necessarily indicative of future performance. This
          presentation may contain forward-looking statements that involve risks
          and uncertainties. Actual future performance, outcomes, and results
          may differ materially from those expressed in forward-looking
          statements as a result of several risks, uncertainties, and
          assumptions. Opinions and estimates are subject to change without
          notice.
        </div>
        <div className="hiddenAnimation text-sm text-[#ffffff]/60 font-poppins mt-4">
          Advice should be sought from an independent financial adviser
          regarding the suitability of the funds before purchasing any shares in
          the funds. If you decide not to seek advice from an independent
          financial adviser, you should consider carefully whether the funds are
          suitable for you. You should read the relevant offering materials
          carefully before making any investment decision. While care has been
          taken in preparing the information contained herein, the information
          is provided to you without warranty of any kind, whether express or
          implied. Second Street Partners makes no representation or warranty as
          to the accuracy, completeness, or reliability of the information and
          shall not have any liability for any representations (express or
          implied) regarding the information contained herein, or for any
          omissions from this presentation, or any other written or oral
          communications transmitted to investors. This presentation has not
          been reviewed by the Financial Services Commission, Mauritius.
        </div>
      </div>
    </div>
  );
};

export default Footer;
