import React, { useEffect } from "react";
import animation from "../../common/animation";
import PhilisophyPicture from "../../assests/Pholosphy.jpeg";
import ConnectNow from "../../common/ConnectNowButton";

const Philosophy = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className="flex flex-col md:flex-row bg-[#243444]" id="philosophy">
      <div className="w-full md:w-[55%] py-20 px-4 md:px-36">
        <div className="hiddenAnimation text-center md:text-start text-white text-2xl md:text-[34px] md:leading-[66px] font-bold font-sans">
          Our Investment Philosophy
        </div>
        <div className="hiddenAnimation text-center md:text-start text-white text-sm leading-[24px] md:text-lg font-poppins font-normal opacity-60 mt-6">
          At Second Street, our investment philosophy centers on delivering
          superior, long-term results while minimizing risks. We employ an
          adaptive long-only approach, utilizing momentum and trend-following
          strategies in US and Indian equities.
        </div>
        <div className="hiddenAnimation text-center md:text-start text-white text-sm leading-[24px] md:text-lg font-poppins font-normal opacity-60 mt-10">
          Our approach emphasizes long-term growth through a rigorous,
          data-driven methodology that minimizes biases and maximizes return
          potential. Differentiating ourselves through tailored solutions and
          transparency, we prioritize a secure and compliant investment
          environment. Our commitment to innovation is evident in our fully
          automated execution model, ensuring a path to financial success for
          our clients.
        </div>
        <div className="hiddenAnimation text-center md:w-[50%] mt-12 px-8 md:px-0">
          <ConnectNow />
        </div>
      </div>
      <div className="hiddenAnimation w-full md:w-[45%] px-4 md:px-0 pb-16 md:pb-0">
        <img
          src={PhilisophyPicture}
          alt="Philosophy"
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default Philosophy;
