import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import HeroSection from "./HeroSection/HeroSection";
import Strategies from "./Strategies/Strategies";
import Companies from "./Companies/Companies";
import WhyUs from "./WhyUs/WhyUs";
import Philosophy from "./Philosphy/Philosophy";
// import Faq from "./Faq/Faq";
import OurTeam from "./OurTeam/OurTeam";
import Footer from "../Footer/Footer";
// import UpdateBox from "../Footer/UpadteBox";
import ContactUsForm from "../Footer/ContactUsForm";

const IndexPage = () => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <Navbar />
      <HeroSection />
      <Strategies />
      <Companies />
      <WhyUs />
      <Philosophy />
      {/*  <Faq />*/}

      <OurTeam />
      {/*  <UpdateBox />*/}

      <Footer setShow={setShow} />
      {show === true ? <ContactUsForm setShow={setShow} show={show} /> : null}
    </div>
  );
};

export default IndexPage;
