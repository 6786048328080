import React from "react";

const style = {
  textDescription: " mt-1 font-rubik font-light text-[18px] opacity-70  pl-4",
  textDescriptionNormal: "font-rubik text-[18px] opacity-70",
};
const StrategyDescription = () => {
  return (
    <div className="pb-[8px]">
      <div className="mt-10 text-black text-[22px]  leading-[30px] font-rubik font-bold">
        Decoding our algorithms
      </div>
      <div className="text-[20px]  font-rubik pt-2">1. Quant Strategies</div>
      <div className={style.textDescription}>
        Our strategies utilize multiple momentum, strength and relative value
        indicators to identify high-quality stocks within indices.
      </div>
      <div className="text-[20px] font-rubik pt-4">2. Generating Alpha</div>
      <div className={style.textDescription}>
        Generating Alpha We aim to outperform the relevant benchmark index over
        a rolling three-year period.
      </div>
      <div className="text-[20px] font-rubik pt-4">3. Smart Beta</div>
      <div className={style.textDescription}>
        We strive to take smart risks to create a portfolio that delivers
        attractive absolute returns while also exhibiting lower volatility
        compared to the broader market.
      </div>
      <div className="mt-10 text-black text-[22px]  leading-[30px] font-rubik font-bold">
        Criteria for Selection
      </div>
      <div className="text-[20px] font-rubik pt-2">1. Momentum</div>
      <div className={style.textDescription}>
         Represents the
        direction and speed of a stock's price movement.
      </div>
      
      <div className="text-[20px] font-rubik pt-4">2. Direction</div>
      <div className={style.textDescription}>
         Measures the resilience in a stock’s price trajectory.
      </div>
      
      <div className="text-[20px] font-rubik pt-4">3. Relative Peformance vs Benchmark</div>
      <div className={style.textDescription}>
         Compares individual stock performance against the benchmark.
      </div>
      
    </div>
  );
};

export default StrategyDescription;
