import React, { useEffect } from "react";
import animation from "../../common/animation";
import styles from "./HeroSection.module.css";
const HeroSection = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className={`${styles.backgroundHero} `} id="home">
      <div className={styles.backgroundImage}>
        <div className={styles.overlay}></div>
        <div className={`py-44 md:py-48 px-4 md:px-36 ${styles.textContent}`}>
          <div className="md:flex flex-col hidden">
            <div className="hiddenAnimation text-white md:text-[74px]  md:leading-[80px] font-sans font-bold">
              Resilient{" "}
            </div>
            <div className="hiddenAnimation text-white text-[74px] leading-[80px] font-sans font-bold">
              investments for an{" "}
            </div>
            <div className="hiddenAnimation text-white text-[74px] leading-[80px] font-sans font-bold">
              unknown future.
            </div>
            <div className="hiddenAnimation w-[70%] text-[#eff0f6] text-lg font-poppins mt-8">
              Seeking to help long-term investors compound their wealth across
              boom & bust environments. Offense wins games. Defense wins
              championships.
            </div>
            <div className="hiddenAnimation flex flex-row mt-8">
              <div className="px-12 py-4 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[4px] hover:scale-105 cursor-pointer">
                Get started
              </div>
              <a
                href="https://cal.com/secondstreet"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="md:px-12 py-4 ml-6 text-xl font-medium text-white border border-[#ffffff] rounded-[4px] hover:bg-[#ffffff] hover:text-[#000000] hover:scale-105 cursor-pointer">
                  Talk to us
                </div>
              </a>
            </div>
          </div>
          <div className="hiddenAnimation md:hidden text-white text-[26px] leading-[37px] text-center font-bold font-sans">
            Resilient investments for an unknown future.
            <div className="hiddenAnimation w-full text-center text-sm leading-[28px] text-[#eff0f6] font-normal font-poppins mt-8">
              Seeking to help long-term investors compound their wealth across
              boom & bust environments. Offense wins games. Defense wins
              championships.
            </div>
            <div className="hiddenAnimation flex flex-row justify-center mt-8">
              <div className="px-6 md:px-12 py-3 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[4px] hover:scale-105 cursor-pointer">
                Get started
              </div>
              <a
                href="https://cal.com/secondstreet"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="px-6 md:px-12 py-3 ml-6 text-xl font-medium text-white border border-[#ffffff] rounded-[4px] hover:bg-[#ffffff] hover:text-[#000000] hover:scale-105 cursor-pointer">
                  Talk to us
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
